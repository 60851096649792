import store from '../containers/App/store';
import {updateAddOns, updateCurrentIndex, updateQuestion, updateQuestions, updateTaskId} from '../redux/actions/createtabActions';
import API from './apiURL';
import Authenticate from './auth';

/* eslint-disable prefer-destructuring */
const {API_URL, HOST_URL} = API;

export const addAddon = async (type) => {
  const storedAddons = store.getState().createTab.addOns;
  const addOns = [...storedAddons];
  try {
    if (process.env.REACT_APP_MODE === 'demo') {
      new Error('error');
    }
    const response = await fetch(`${API_URL}/v1/task/addAddOn`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${Authenticate.getToken()}`,
      },
      body: JSON.stringify({
        token: Authenticate.getToken(),
        type,
      }),
    });
    const res = await response.json();
    if (res) {
      if (res.addOnId && res.data) {
        addOns.push({...res.data});
      }
      store.dispatch(updateAddOns(addOns));
    }
  } catch (error) {
    store.dispatch(updateAddOns(addOns));
  }
  return true;
};

export const addQuestion = async (type, lastIndex) => {
  const storeQuestions = store.getState().createTab.questions;
  const questions = [...storeQuestions];
  try {
    if (process.env.REACT_APP_MODE === 'demo') {
      new Error('error');
    }
    const response = await fetch(`${API_URL}/v1/task/addQuestion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${Authenticate.getToken()}`,
      },
      body: JSON.stringify({
        token: Authenticate.getToken(),
        type,
      }),
    });
    const res = await response.json();
    if (res) {
      if (res.questionid && res.data) {
        if (type === 'mcq') {
          questions.push({...res.data, subgroupQuestions: [], subGroup: []});
        } else {
          questions.push({...res.data});
        }
      }
      store.dispatch(updateQuestions(questions));
      store.dispatch(updateCurrentIndex(questions.length - 1));
    }
  } catch (error) {
    store.dispatch(updateQuestions(questions));
    store.dispatch(updateCurrentIndex(questions.length - 1));
  }
};

export const addSubQuestion = (type, index) => {
  const storeQuestions = store.getState().createTab.questions;
  const questions = [...storeQuestions];
  if (index !== undefined) {
    const question = {...questions[index]};

    if (questions[index] !== undefined) {
      const newSubGroup = question.subGroup;
      newSubGroup.push([]);
      question.subGroup = newSubGroup;
      question.subgroupQuestions.push('');

      store.dispatch(updateQuestion(question, index));
    }
  }
};

export const addMCQOptions = (type, index, questionIndex, parentIndex) => {
  const storeQuestions = store.getState().createTab.questions;
  const questions = [...storeQuestions];
  if (questionIndex !== undefined) {
    const question = {...questions[questionIndex]};

    if (questions[questionIndex] !== undefined) {
      const subQuestions = question.subFormList;
      if (subQuestions[index]) {
        const exactQuestion = subQuestions[index];
        if (!exactQuestion.subGroup || !exactQuestion.subgroupQuestions) {
          exactQuestion.subGroup = [];
          exactQuestion.subgroupQuestions = [];
        }
        exactQuestion.subGroup.push([]);
        exactQuestion.subgroupQuestions.push('');
        subQuestions[index] = {...exactQuestion};
        question.subFormList = [...subQuestions];
      }
      store.dispatch(updateQuestion(question, questionIndex));
    }
  }
};

export const addSubForm = async (type, index) => {
  const storeQuestions = store.getState().createTab.questions;
  const questions = [...storeQuestions];
  if (index !== undefined) {
    const question = {...questions[index]};
    if (questions[index] !== undefined) {
      try {
        if (process.env.REACT_APP_MODE === 'demo') {
          new Error('error');
        }
        const response = await fetch(`${API_URL}/v1/task/addQuestion`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${Authenticate.getToken()}`,
          },
          body: JSON.stringify({
            token: Authenticate.getToken(),
            type,
          }),
        });
        const res = await response.json();
        if (res) {
          if (res.questionid && res.data) {
            let newSubFormList = [];
            if (question.subFormList) {
              newSubFormList = [...question.subFormList];
            }
            if (type === 'mcq') {
              newSubFormList.push({...res.data, subgroupQuestions: [], subGroup: []});
            } else {
              newSubFormList.push({...res.data});
            }
            question.subFormList = newSubFormList;
          }
        }

        store.dispatch(updateQuestion(question, index));
      } catch (error) {
        store.dispatch(updateQuestion(question, index));
      }
    }
  }
};
export const addSubGroupQuestion = async (type, index, subGroupIndex) => {
  const storeQuestions = store.getState().createTab.questions;
  const questions = [...storeQuestions];
  if (index !== undefined) {
    const question = {...questions[index]};
    console.log('sduahsiud', index, subGroupIndex, question);
    if (questions[index] !== undefined && question.subGroup[subGroupIndex] !== undefined) {
      try {
        if (process.env.REACT_APP_MODE === 'demo') {
          new Error('error');
        }
        const response = await fetch(`${API_URL}/v1/task/addQuestion`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${Authenticate.getToken()}`,
          },
          body: JSON.stringify({
            token: Authenticate.getToken(),
            type,
          }),
        });
        const res = await response.json();
        if (res) {
          if (res.questionid && res.data) {
            const newSubGroup = [...question.subGroup];
            if (type === 'dropdown') {
              newSubGroup[subGroupIndex].push({...res.data, subgroupQuestions: [], subGroup: []});
            } else {
              newSubGroup[subGroupIndex].push({...res.data});
            }
            question.subGroup = newSubGroup;
          }
          store.dispatch(updateQuestion(question, index));
        } else {
          store.dispatch(updateQuestion(question, index));
        }
      } catch (error) {
        console.log(error);
        store.dispatch(updateQuestion(question, index));
      }
    }
  }
};

export const setTaskPermission = (taskId, email, userPermissions, firstName, lastName) => {
  return new Promise((resolve, reject) => {
    try {
      Authenticate.doFetch('POST', {taskId, email, userPermissions, firstName, lastName}, `${API_URL}/task/addTaskPermissions`)
        .then((response) => response.json())
        .then((res) => {
          resolve(true);
        })
        .catch((error) => {});
    } catch (error) {
      reject(false);
    }
  });
};

const filterText = (questionText) => {
  if (!questionText) return '';
  questionText = questionText.replace(/<style([\s\S]*?)<\/style>/gi, '');
  questionText = questionText.replace(/<script([\s\S]*?)<\/script>/gi, '');
  questionText = questionText.replace(/<\/div>/gi, '\n');
  questionText = questionText.replace(/<\/li>/gi, '\n');
  questionText = questionText.replace(/<li>/gi, '  *  ');
  questionText = questionText.replace(/<\/ul>/gi, '\n');
  questionText = questionText.replace(/<\/p>/gi, '\n');
  questionText = questionText.replace(/<br\s*[\/]?>/gi, '\n');
  questionText = questionText.replace(/\&nbsp;/g, ' ');
  questionText = questionText.replace(/<[^>]+>/gi, '');
  return questionText.trim();
};

const getReminderQns = (addOns = []) => {
  const qnIds = [];
  for (let i = 0; i < addOns.length; i++) {
    if (addOns[i].settings && addOns[i].settings.contactFromTask && addOns[i].settings.contactFromTask.value) {
      qnIds.push(addOns[i].settings.contactFromTask.value);
    }
  }
  return qnIds;
};

export const TaskToBackend = async (status) => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return;
  }
  let draft = true,
    state = {},
    saga = false;
  let {createTab: home} = store.getState();
  let {createTab: create} = store.getState();

  const uploadObj = {};
  const qid = [];
  const addOnIds = [];

  let draftType = false;
  if (draft === true) {
    draftType = true;
  }

  let isNotEmpty = true;
  uploadObj.taskname = home.taskName;
  uploadObj.taskDescription = home.taskDescription;
  uploadObj.color = home.color;
  uploadObj.icon = home.icon;
  uploadObj.infoTask = home.infoTask;
  uploadObj.label = home.label;

  if (!draft) {
    for (const i in create.questions) {
      const question = create.questions[i];
      if (question.question === '') {
        isNotEmpty = false;
      }
    }
  }

  if (isNotEmpty) {
    const reminderQns = getReminderQns(create.addOns);
    for (const i in create.questions) {
      const question = create.questions[i];
      question.settings.isReminder = reminderQns.includes(question.questionid);

      if (question.subGroup instanceof Array) {
        if (question.subgroupQuestions instanceof Array)
          question.subgroupQuestions = question.subgroupQuestions.map((item, i) => {
            if (!item) return `Option ${parseInt(i) + 1}`;
            return filterText(item);
          });
        for (let sg in question.subGroup) {
          let subGroup = question.subGroup[sg];

          if (subGroup instanceof Array) {
            for (let sgi in subGroup) {
              let subGroupQuestion = subGroup[sgi];
              subGroupQuestion.settings.isReminder = reminderQns.includes(subGroupQuestion.questionid);
              console.log(subGroupQuestion.question, 'questionn');
              if (subGroupQuestion.question) {
                subGroupQuestion.question = filterText(subGroupQuestion.question);
              }
              try {
                const response = await fetch(`${API_URL}/v1/task/updateQuestion`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${Authenticate.getToken()}`,
                  },
                  body: JSON.stringify({
                    token: Authenticate.getToken(),
                    question: {
                      ...subGroupQuestion,
                      _id: undefined,
                      settings: {
                        ...subGroupQuestion.settings,
                        imageFileList: undefined,
                      },
                    },
                  }),
                });
                const res = await response.json();
                if (res) {
                  if (res.status === 'success') {
                    // qid.push(question.questionid);
                    subGroup[sgi] = subGroupQuestion;
                  }

                  //
                }
              } catch (error) {}
            }

            question.subGroup[sg] = subGroup;
          }
        }
      }

      if (question.subFormList instanceof Array) {
        for (let kl in question.subFormList) {
          let subQuestion = question.subFormList[kl];
          if (subQuestion.question) {
            subQuestion.question = filterText(subQuestion.question);
          }
          try {
            const response = await fetch(`${API_URL}/v1/task/updateQuestion`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${Authenticate.getToken()}`,
              },
              body: JSON.stringify({
                token: Authenticate.getToken(),
                question: {
                  ...subQuestion,
                  _id: undefined,
                  settings: {
                    ...subQuestion.settings,
                    imageFileList: undefined,
                  },
                },
              }),
            });
            const res = await response.json();
            if (res) {
              if (res.status === 'success') {
                // qid.push(question.questionid);
                question.subFormList[kl] = subQuestion;
              }

              //
            }
          } catch (error) {}
        }
      }

      if (question.question) {
        question.question = filterText(question.question);
      }
      try {
        const response = await fetch(`${API_URL}/v1/task/updateQuestion`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${Authenticate.getToken()}`,
          },
          body: JSON.stringify({
            token: Authenticate.getToken(),
            question: {
              ...question,
              _id: undefined,
              settings: {
                ...question.settings,
                imageFileList: undefined,
              },
            },
          }),
        });
        const res = await response.json();
        if (res) {
          if (res.status === 'success') {
            qid.push(question.questionid);
            create.questions[i] = question;
          }

          //
        }
      } catch (error) {}
    }

    for (const i in create.addOns) {
      // TODO reminder flag for date questions
      const addOn = create.addOns[i];

      if (addOn.question) {
        addOn.question = filterText(addOn.question);
      }
      try {
        const response = await fetch(`${API_URL}/v1/task/updateAddOn`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${Authenticate.getToken()}`,
          },
          body: JSON.stringify({
            token: Authenticate.getToken(),
            addOn: {
              ...addOn,
              _id: undefined,
              settings: {
                ...addOn.settings,
                imageFileList: undefined,
              },
            },
          }),
        });
        const res = await response.json();
        if (res) {
          if (res.status === 'success') {
            addOnIds.push(addOn.addOnId);
            create.addOns[i] = addOn;
          }

          //
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (!saga) {
      store.dispatch(updateQuestions(create.questions));
    }
    uploadObj.questionarray = qid;
    uploadObj.addOnIds = addOnIds;
    uploadObj.taskid = home.taskId;
    uploadObj.taskDescription = home.taskDescription;
    uploadObj.status = status;
    if (home.taskSettings instanceof Object) {
      uploadObj.taskSettings = {...home.taskSettings};
      uploadObj.taskSettings.lastModified = new Date().toString().slice(0, 15);
      if (uploadObj.taskSettings) {
        //uploadObj.taskSettings.userGroups=undefined;
        //uploadObj.taskSettings.locationGroups=undefined;
      }
    }
    try {
      const response = await fetch(`${API_URL}/task/addTask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: Authenticate.getToken(),
          task: uploadObj,
          draftType,
        }),
      });

      const res = await response.json();
      if (res) {
        if (res.taskid && !home.taskId) {
          //return true;
          await setTaskPermission(res.taskid);
          store.dispatch(updateTaskId(res.taskid));
          window.location.replace(`create/${res.taskid}`);
        }
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  } else {
    if (state !== 'added') {
    }
  }
};

export const cloneTask = async (draft, state) => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return;
  }
  let {home} = store.getState();
  let {create} = store.getState();
  if (state && state.home && state.create) {
    home = store.getState().home;
    create = store.getState().createTab;
  }

  const uploadObj = {};
  const qid = [];

  let draftType = false;
  if (draft === true) {
    draftType = true;
  }

  let isNotEmpty = true;
  uploadObj.taskname = `${home.taskName} clone#`;
  uploadObj.taskDescription = 'Enter Description';
  for (const i in create.questions) {
    const question = create.questions[i];
    if (question.question === '') {
      isNotEmpty = false;
    }
  }
  if (isNotEmpty) {
    for (const i in create.questions) {
      const question = create.questions[i];
      try {
        const response = await fetch(`${API_URL}/task/addQuestion`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${Authenticate.getToken()}`,
          },
          body: JSON.stringify({
            token: Authenticate.getToken(),
            question: {
              ...question,
              _id: undefined,
              questionid: undefined,
              settings: {
                ...question.settings,
                imageFileList: undefined,
              },
            },
          }),
        });
        const res = await response.json();
        if (res) {
          if (res.questionid) {
            question.questionid = res.questionid;
            qid.push(question.questionid);
            // create.questions[i] = question;
          }

          //
        }
      } catch (error) {}
    }
    store.dispatch(updateQuestions(create.questions));
    uploadObj.questionarray = qid;
    uploadObj.taskid = null;

    if (home.taskSettings instanceof Object) {
      uploadObj.taskSettings = {...home.taskSettings};
      uploadObj.taskSettings.lastModified = new Date().toString().slice(0, 15);
      if (uploadObj.taskSettings) {
        //uploadObj.taskSettings.userGroups=undefined;
        //uploadObj.taskSettings.locationGroups=undefined;
      }
    }
    try {
      const response = await fetch(`${API_URL}/task/addTask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: Authenticate.getToken(),
          task: uploadObj,
          draftType,
        }),
      });

      const res = await response.json();
      if (res) {
        if (res.taskid && !home.taskId) {
          store.dispatch(updateTaskId(res.taskid));
        }
        if (res.taskid) {
          await setTaskPermission(res.taskid);
          // const hide = message.loading("Cloning the task..!", 0);
          // setTimeout(hide, 2500);
          const redirect = () => (window.location.href = `${HOST_URL}/home/create/${res.taskid}`);
          setTimeout(redirect, 3000);
        } else {
          //  message.error("Enter task name and submit");
        }

        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  } else {
    //   message.error("Question Name can't be empty!");
  }
};

export const TaskSettingstoBackend = async (draft, state) => {
  if (process.env.REACT_APP_MODE === 'demo') {
    return;
  }
  let {home} = store.getState();
  if (state && state.home && state.create) {
    home = store.getState().home;
  }

  const uploadObj = {};

  let draftType = false;
  if (draft === true) {
    draftType = true;
  }

  uploadObj.taskname = home.taskName;
  uploadObj.taskDescription = home.taskDescription;
  uploadObj.status = home.status;
  uploadObj.questionarray = home.taskQuestions;
  uploadObj.taskid = home.taskId;
  if (home.taskSettings instanceof Object) {
    uploadObj.taskSettings = {...home.taskSettings};
    if (uploadObj.taskSettings) {
      //uploadObj.taskSettings.userGroups=undefined;
      //uploadObj.taskSettings.locationGroups=undefined;
    }
  }
  try {
    const response = await fetch(`${API_URL}/task/addTask`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: Authenticate.getToken(),
        task: uploadObj,
        draftType,
      }),
    });

    const res = await response.json();
    if (res) {
      if (res.taskid && !home.taskId) {
        //return true;
        store.dispatch(updateTaskId(res.taskid));
      }
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const getQuestionName = (id, questionArray) => {
  if (questionArray instanceof Array) {
    for (const i in questionArray) {
      const question = questionArray[i];
      if (question.questionid === id) {
        return question.question;
      }
    }
  }
  return null;
};
export const getQuestionType = (id, questionArray) => {
  if (questionArray instanceof Array) {
    for (const i in questionArray) {
      const question = questionArray[i];
      if (question.questionid === id) {
        return question.type;
      }
    }
  }
  return null;
};
