import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row} from 'reactstrap';

import {setCurrentLocations, setLastSync} from '../../../redux/actions/locationTrackerAction';
import FpButton from '../../../shared/fpComponents/buttons';
import OnBoardWatch from '../../../shared/fpComponents/onBoardWatch';
import Aux from '../../../shared/wrapper';
import TopbarProfile from '../common/TopbarProfile';
import TopbarSidebarButton from '../common/TopbarSidebarButton';
import TopBarName from './TopBarName';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  getLocations = () => {
    this.props.setLastSync(new Date().getTime() - 5 * 60 * 1000); // will trigger componentDidUpdate in child components
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.taskName !== this.props.taskName) {
      if (nextProps.taskName) {
        if (!this.props.collapsed) {
          this.props.changeSidebarVisibility();
        }
      } else {
        if (this.props.collapsed) {
          this.props.changeSidebarVisibility();
        }
      }
    }
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
      collapsed,
      companyId,
      userMode,
      currentLocations,
      lastSynced,
    } = this.props;
    const {buffer_web, buffer_mobile, isTrial, trialUpto} = this.props.globalAccess;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {collapsed ? <Link className="topbar__logo1" to="/home/workflows" /> : <Link className="topbar__logo" to="/home/workflows" />}
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />

            <h5
              className={'fp-text-sub-heading-1'}
              onClick={() => this.props.history.push(`/${companyId}/home/location-tracking`)}
              style={{cursor: 'pointer', color: '#373737', marginTop: 'auto', marginBottom: 'auto'}}>
              Location Tracking{' '}
            </h5>

            {this.props.userMode ? (
              <React.Fragment>
                <ArrowForwardIosIcon style={{width: '12px', marginTop: 'auto', marginBottom: 'auto', marginLeft: 8, marginRight: 8}} />

                <TopBarName name={this.props.userMode} companyId={companyId} users={currentLocations || []} />
              </React.Fragment>
            ) : (
              <OnBoardWatch page="location-tracking" companyId={companyId} />
            )}
          </div>

          <div className="topbar__right">
            <Aux>
              <Row style={{height: '100%', alignItems: 'center', textAlign: 'center', marginRight: '5px'}}>
                <div style={{marginLeft: '20px'}}>
                  {isTrial ? (
                    <div className="trialmsg"> {trialUpto} Days Left On Trial </div>
                  ) : buffer_web ? (
                    <marquee className="buffer-alert">
                      {' '}
                      The Application will be inactive in {buffer_web} days due to overdue payment{' '}
                    </marquee>
                  ) : (
                    ''
                  )}
                </div>

                {!userMode ? (
                  <TopbarProfile companyId={companyId} />
                ) : (
                  <div>
                    <FpButton size="small" type="primary" disabled={this.state.loading} onClick={this.getLocations}>
                      {' '}
                      {this.state.loading ? 'Refreshing' : 'Refresh'}
                    </FpButton>
                  </div>
                )}
              </Row>
            </Aux>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLocations: state.locationTracker.currentLocations,
  userMode: state.locationTracker.userMode,
  lastSynced: state.locationTracker.lastSynced,
});

const mapDispatchToProps = {
  setCurrentLocations,
  setLastSync,
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
