import {CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, REMOVE_SNACKBAR} from './types';

export const enqueueSnackbar = (notification) => (dispatch) => {
  console.log('caalled=', notification);
  const key = notification.options && notification.options.key;
  dispatch({
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  });
};

export const closeSnackbar = (key) => (dispatch) => {
  dispatch({
    key,
    type: CLOSE_SNACKBAR,
    dismissAll: !key,
  });
};

export const removeSnackbar = (key) => (dispatch) => {
  dispatch({
    key,
    type: REMOVE_SNACKBAR,
  });
};
