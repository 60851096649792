import {
  SET_GLOBAL_ACCESS,
  SET_GLOBAL_DATASET,
  SET_GLOBAL_PDF,
  SET_GLOBAL_TASK,
  SET_GLOBAL_WORKFLOW,
  SET_NOTIFICATIONS,
  SET_ONBOARD_STATUS,
} from './types';

export const setGlobalTask = (globalTask) => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_TASK,
    globalTask,
  });
};

export const setGlobalWorkflow = (globalWorkflow) => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_WORKFLOW,
    globalWorkflow,
  });
};

export const setGlobalDataset = (globalDataset) => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_DATASET,
    globalDataset,
  });
};

export const setGlobalPdf = (globalPdf) => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_PDF,
    globalPdf,
  });
};

export const setGlobalAccess = (globalAccess) => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_ACCESS,
    globalAccess,
  });
};

export const setOnboardStatus = (onBoardStatus) => (dispatch) => {
  dispatch({
    type: SET_ONBOARD_STATUS,
    onBoardStatus,
  });
};

export const setNotifications = (notifications) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATIONS,
    notifications,
  });
};
