import MessageIcon from '@material-ui/icons/Message';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {enqueueSnackbar} from '../../../redux/actions/notistackActions';
import Api from '../../../shared/api';
import ApiUrl from '../../../shared/apiURL';
import {FpModal} from '../../../shared/fpComponents';
import TopbarProfile from '../common/TopbarProfile';
import TopbarSidebarButton from '../common/TopbarSidebarButton';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  state = {
    description: '',
  };

  handleContactUs = async () => {
    if (!this.state.description) {
      return this.props.enqueueSnackbar({
        message: 'Please fill your query!',
        options: {
          key: 'expire',
          variant: 'error',
          preventDuplicate: true,
        },
      });
    }
    const send = await Api.doFetch('POST', {message: this.state.description}, `${ApiUrl.pingtoSlack}`);
    if (send && send.status && send.status === 'sent') {
      this.setState({description: ''});
      return this.props.enqueueSnackbar({
        message: 'Query sent! Our team will contact you soon!',
        options: {
          key: 'slack',
          variant: 'success',
          preventDuplicate: true,
        },
      });
    }
  };

  render() {
    const {changeMobileSidebarVisibility, changeSidebarVisibility, collapsed, companyId} = this.props;
    const {buffer_web, buffer_mobile, isTrial, trialUpto} = this.props.globalAccess;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {collapsed ? <Link className="topbar__logo1" to="/home/workflows" /> : <Link className="topbar__logo" to="/home/workflows" />}
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
          </div>
          <div className="topbar__right">
            <div className={'profile-contact-us-container'}>
              <FpModal
                title="Contact Us"
                customButton={
                  <button className={'profile-contact-us-btn'} style={{marginRight: isTrial || buffer_mobile || buffer_web ? 8 : 0}}>
                    <MessageIcon color={'inherit'} style={{fontSize: 18}} /> <div style={{width: 4}} /> {'Contact Us'}
                  </button>
                }
                showCancel={true}
                onCancel={() => this.setState({description: ''})}
                okLabel="Send"
                onOk={this.handleContactUs}>
                <textarea
                  type="text"
                  placeholder={'Please enter your query here'}
                  className={'profile-contact-us-input'}
                  value={this.state.description}
                  onChange={(event) => this.setState({description: event.target.value})}
                />
              </FpModal>
              <div style={{marginRight: isTrial || buffer_mobile || buffer_web ? 16 : 0}}>
                {isTrial ? (
                  <div className="trialmsg" style={{color: 'rgb(220,20,60)'}}>
                    {trialUpto} Days Left On Trial
                  </div>
                ) : buffer_web ? (
                  <marquee className="buffer-alert"> The Application will be inactive in {buffer_web} days due to overdue payment</marquee>
                ) : (
                  ''
                )}
              </div>
            </div>
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  enqueueSnackbar,
};

export default connect(null, mapDispatchToProps)(Topbar);
