import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Api from '../../../shared/api';

export default class TopbarMenuLinks extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };

  logOut = async () => {
    await Api.logOut();
  };

  render() {
    const {title, icon, path, companyId} = this.props;
    if (path === '/login') {
      return (
        <p className="topbar__link" onClick={this.logOut}>
          <span className={`topbar__link-icon lnr lnr-${icon}`} />
          <p className="topbar__link-title">{title}</p>
        </p>
      );
    }

    if (path === '/profile') {
      return (
        <Link className="topbar__link" to={`/${companyId}/home/profile`}>
          <span className={`topbar__link-icon lnr lnr-${icon}`} />
          <p className="topbar__link-title">{title}</p>
        </Link>
      );
    }

    return (
      <p className="topbar__link" onClick={() => this.props.changeTeam(title)}>
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">{title}</p>
      </p>
    );
  }
}
