import React from 'react';
import ReactGA from 'react-ga';

import {Event} from '../services';
import api from './apiURL';

async function handleErrors(response) {
  if (response.status !== 200) {
    if (response.status === 401) {
      return;
      localStorage.removeItem('fp-token');
      localStorage.removeItem('fp-profile');
      localStorage.removeItem('fp-first-time');
      setTimeout(() => {
        window.location = '/login';
      }, 2000);
      // swal('Unauthorized!', 'Please login again', 'error');
    } else {
      const parsed = await response.json();
      //   swal('Internal Server Error!', parsed.message, 'error');
      console.log(parsed.message);
    }

    throw Error(response.statusText);
  }
  return response;
}

function Api() {
  const logIn = async (token, data) => {
    if (window.location.origin === 'https://client.fieldproxy.com') {
      ReactGA.set({
        userId: data.firstName,
        company: data.companyName,
      });
      Event('Login', 'Logged In', data.companyName);
    }

    localStorage.setItem('fp-profile', JSON.stringify(data));
    localStorage.setItem('fp-token', token);
  };
  const getProfile = async () => {
    return JSON.parse(localStorage.getItem('fp-profile'));
  };
  const setFirstTimeUser = async (value) => {
    localStorage.setItem('fp-first-time', value);
  };
  const getFirstTimeUser = async () => localStorage.getItem('fp-first-time');
  const logOut = () => {
    console.log('logout');
    localStorage.removeItem('fp-token');
    localStorage.removeItem('fp-profile');
    localStorage.removeItem('fp-first-time');
    setTimeout(() => {
      window.location = '/login';
    }, 500);
  };
  const getToken = () => localStorage.getItem('fp-token');
  const getUserData = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user && user.userData ? user.userData : '';
  };
  const isAdmin = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return !!(user && user.userData && user.userData.role === 'admin');
  };
  const isAuthenticated = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return !!(user && user.token);
  };
  const checkEmail = async (type, data, url) => {
    let reqData = {...data};
    if (data === null || data === undefined) {
      reqData = {};
    }
    return (
      fetch(url, {
        method: type,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...reqData}),
      })
        .then(handleErrors)
        .then((res) => res.json())
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
    );
  };
  const doFetch = async (type, data, url) => {
    let reqData = {...data};
    if (data === null || data === undefined) {
      reqData = {};
    }
    const token = getToken();
    return fetch(url, {
      method: type,
      headers:
        type !== 'GET'
          ? {
              'x-access-token': token,
              'content-type': 'Application/json',
            }
          : {
              'x-access-token': token,
            },
      body: type !== 'GET' ? JSON.stringify({...reqData}) : null,
    })
      .then(handleErrors)
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const callWithToken = async (token, type, data, url) => {
    let reqData = {...data};
    if (data === null || data === undefined) {
      reqData = {};
    }
    return fetch(url, {
      method: type,
      headers:
        type !== 'GET'
          ? {
              'x-access-token': token,
              'content-type': 'Application/json',
            }
          : {
              'x-access-token': token,
            },
      body: type !== 'GET' ? JSON.stringify({...reqData}) : null,
    })
      .then(handleErrors)
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const doBlob = async (type, data, url) => {
    let reqData = {...data};
    if (data === null || data === undefined) {
      reqData = {};
    }
    const token = getToken();
    return fetch(url, {
      method: type,
      headers:
        type !== 'GET'
          ? {
              'x-access-token': token,
              'content-type': 'Application/json',
            }
          : {
              'x-access-token': token,
            },
      body: type !== 'GET' ? JSON.stringify({...reqData}) : null,
    });
  };
  const doUpload1 = async (data, url) =>
    fetch(url, {
      method: 'POST',
      headers: {
        'cotent-type': 'application/x-www-form-urlencoded',
        'x-access-token': getToken(),
      },
      body: data,
    })
      .then(handleErrors)
      .then((res) => res.json())
      .catch((err) => console.log(err));
  const doUpload = async (data, url = api.upload) =>
    fetch(url, {
      method: 'POST',
      headers: {
        'x-access-token': getToken(),
      },
      body: data,
    })
      .then(handleErrors)
      .then((res) => res.json())
      .catch((err) => console.log(err));
  return {
    logIn,
    logOut,
    getToken,
    getUserData,
    isAdmin,
    isAuthenticated,
    checkEmail,
    doFetch,
    callWithToken,
    setFirstTimeUser,
    getFirstTimeUser,
    doUpload,
    doUpload1,
    getProfile,
    doBlob,
  };
}

// eslint-disable-next-line import/no-default-export
export default new Api();
