import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';

import TopbarProfile from '../common/TopbarProfile';
import TopbarSidebarButton from '../common/TopbarSidebarButton';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const {changeMobileSidebarVisibility, changeSidebarVisibility, collapsed, isManagerPermission, companyId} = this.props;
    const {buffer_web, buffer_mobile, isTrial, trialUpto} = this.props.globalAccess;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {collapsed ? <Link className="topbar__logo1" to="/home/workflows" /> : <Link className="topbar__logo" to="/home/workflows" />}

            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <React.Fragment>
              <ArrowBackIosOutlinedIcon fontSize="small" style={{cursor: 'pointer'}} onClick={() => window.history.back()} />
              <h5
                style={{
                  fontSize: '21px',
                  fontWeight: '500',
                  color: '#373737',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: '1rem',
                }}>
                {'Manager Permissions'}
              </h5>
            </React.Fragment>
          </div>
          <div className="topbar__right">
            {isTrial ? (
              <div className="trialmsg"> {trialUpto} Days Left On Trial </div>
            ) : buffer_web ? (
              <marquee className="buffer-alert"> The Application will be inactive in {buffer_web} days due to overdue payment</marquee>
            ) : (
              ''
            )}
            <TopbarProfile companyId={companyId} type="managerPermission" />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
