import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import AddButton from '../../../assets/images/add-button.svg';
import {openDataSetModal} from '../../../redux/actions/dataSet';
import TopbarProfile from '../common/TopbarProfile';
import TopbarSidebarButton from '../common/TopbarSidebarButton';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    collapsed: PropTypes.string.isRequired,
  };

  render() {
    const {changeMobileSidebarVisibility, changeSidebarVisibility, collapsed, companyId} = this.props;
    const {buffer_web, buffer_mobile, isTrial, trialUpto} = this.props.globalAccess;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left" style={{width: '60% !important'}}>
            {collapsed ? <Link className="topbar__logo1" to="/home/workflows" /> : <Link className="topbar__logo" to="/home/workflows" />}
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <h5
              className={'fp-text-sub-heading-1'}
              style={{
                color: '#333333',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}>
              Dataset
            </h5>
            <img
              onClick={() => {
                this.props.openDataSetModal(true);
              }}
              src={AddButton}
              alt="Add"
              style={{marginTop: 'auto', marginBottom: 'auto', width: '1.3rem', marginLeft: ' 1rem', cursor: 'pointer'}}
            />
          </div>
          <div className="topbar__right">
            {isTrial ? (
              <div className="trialmsg"> {trialUpto} Days Left On Trial </div>
            ) : buffer_web ? (
              <marquee className="buffer-alert"> The Application will be inactive in {buffer_web} days due to overdue payment</marquee>
            ) : (
              ''
            )}
            <TopbarProfile companyId={companyId} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.dataSet,
  }),
  {
    openDataSetModal,
  },
)(Topbar);
