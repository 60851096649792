import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  selectWorkflow,
  setActiveTab,
  setParentPrimaryResponse,
  setParentWorkflow,
  setTaskName,
  setWorkflowName,
} from '../../../redux/actions/responseActions';
import Aux from '../../../shared/wrapper';
import TopbarProfile from '../common/TopbarProfile';
import TopbarSidebarButton from '../common/TopbarSidebarButton';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  toggle = (name) => {
    if (this.props.taskName) {
      this.props.setActiveTab('main');
      this.props.setTaskName('');
      const workflow = this.props.globalWorkflow.find((e) => e.name === name);
      if (workflow.parentWorkflowId) {
        const parentWorkflow = this.props.globalWorkflow.find((e) => e.workflowId === workflow.parentWorkflowId);
        this.props.setParentWorkflow(parentWorkflow.name);
      } else {
        this.props.setParentWorkflow('');
      }
      this.props.selectWorkflow(workflow.workflowId);
      this.props.setParentPrimaryResponse('');
    } else {
      const workflow = this.props.globalWorkflow.find((e) => e.name === name);
      if (workflow.parentWorkflowId) {
        const parentWorkflow = this.props.globalWorkflow.find((e) => e.workflowId === workflow.parentWorkflowId);
        this.props.setParentWorkflow(parentWorkflow.name);
      } else {
        this.props.setParentWorkflow('');
      }
      this.props.selectWorkflow(workflow.workflowId);
      this.props.setActiveTab('main');
      this.props.setParentPrimaryResponse('');
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.taskName !== this.props.taskName) {
      if (nextProps.taskName) {
        if (!this.props.collapsed) {
          this.props.changeSidebarVisibility();
        }
      } else {
        if (this.props.collapsed) {
          this.props.changeSidebarVisibility();
        }
      }
    }
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
      collapsed,
      workflowName,
      parentWorkflowName,
      taskName,
      companyId,
    } = this.props;
    const {buffer_web, buffer_mobile, isTrial, trialUpto} = this.props.globalAccess;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {collapsed ? <Link className="topbar__logo1" to="/home/workflows" /> : <Link className="topbar__logo" to="/home/workflows" />}
            {taskName ? null : (
              <TopbarSidebarButton
                changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                changeSidebarVisibility={changeSidebarVisibility}
              />
            )}

            <h5
              className={'fp-text-sub-heading-1'}
              style={{
                color: '#373737',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: taskName ? '1rem' : 0,
              }}>
              Responses
            </h5>
            <ArrowForwardIosIcon style={{width: '12px', marginTop: 'auto', marginBottom: 'auto', marginLeft: 8, marginRight: 8}} />
            {parentWorkflowName ? (
              <Aux>
                <h5
                  className={'fp-text-sub-heading-1'}
                  style={{
                    cursor: 'pointer',
                    color: '#373737',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                  onClick={() => this.toggle(parentWorkflowName)}>
                  {parentWorkflowName}
                </h5>
                <ArrowForwardIosIcon style={{width: '18px', marginTop: 'auto', marginBottom: 'auto'}} />
              </Aux>
            ) : (
              ''
            )}
            <h5
              className={'fp-text-sub-heading-1'}
              style={{cursor: 'pointer', color: '#373737', marginTop: 'auto', marginBottom: 'auto'}}
              onClick={() => this.toggle(workflowName)}>
              {workflowName}
            </h5>
            {taskName ? (
              <React.Fragment>
                <ArrowForwardIosIcon style={{width: '12px', marginTop: 'auto', marginBottom: 'auto'}} />
                <h5 style={{fontSize: '16px', fontWeight: '400', color: '#373737', marginTop: 'auto', marginBottom: 'auto'}}>{taskName}</h5>
              </React.Fragment>
            ) : null}
          </div>

          <div className="topbar__right">
            {isTrial ? (
              <div className="trialmsg"> {trialUpto} Days Left On Trial </div>
            ) : buffer_web ? (
              <marquee className="buffer-alert"> The Application will be inactive in {buffer_web} days due to overdue payment </marquee>
            ) : (
              ''
            )}
            {taskName ? null : <TopbarProfile companyId={companyId} />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({response: {workflowName, taskName, parentWorkflowName}, global: {globalTask, globalWorkflow}}) => ({
    workflowName,
    taskName,
    parentWorkflowName,
    globalTask,
    globalWorkflow,
  }),
  {
    setActiveTab,
    setTaskName,
    setWorkflowName,
    selectWorkflow,
    setParentWorkflow,
    setParentPrimaryResponse,
  },
)(Topbar);
