import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {makeStyles} from '@material-ui/core/styles';
import ArrowDropDownIcon from 'mdi-react/ArrowDropDownIcon';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {useSnackbar} from 'notistack';

const options = ['Submit'];

const useStyles = makeStyles((theme) => ({
  button: {
    background: '#373737',
    borderColor: '#373737',
  },
  rootButton: {
    padding: '6px 10px',
    color: '#2b2b2b',
    fontWeight: 'light',

    background: 'white',
    borderColor: '#373737',

    '&:hover': {
      background: 'white',
      borderColor: '#373737',
      color: 'black',
    },
  },
}));

export default function SubmitButton({TaskToBackend, openTaskSettings}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const classes = useStyles();
  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup aria-label="outlined primary split button group" color="default" ref={anchorRef}>
            <Button
              variant="outlined"
              className={classes.rootButton}
              disableElevation
              onClick={async () => {
                let text = await TaskToBackend('active');
                if (text) {
                  enqueueSnackbar('Saved  successfully', {
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  });
                } else {
                  enqueueSnackbar('Failed to save', {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  });
                }
              }}>
              {options[selectedIndex]}
            </Button>
            <Button
              disableElevation
              variant="outlined"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              className={classes.rootButton}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={selectedIndex != undefined ? index === selectedIndex : 0}
                          onClick={(event) => handleMenuItemClick(event, index)}>
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </>
  );
}
