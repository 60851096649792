import InfoOutlined from 'mdi-react/InfoOutlineIcon';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import AddButton from '../../../assets/images/add-button.svg';
import {openTaskModal} from '../../../redux/actions/task';
import TopbarProfile from '../common/TopbarProfile';
import TopbarSidebarButton from '../common/TopbarSidebarButton';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const {changeMobileSidebarVisibility, changeSidebarVisibility, collapsed, companyId} = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left" style={{width: '60% !important'}}>
            {collapsed ? <Link className="topbar__logo1" to="/home/workflows" /> : <Link className="topbar__logo" to="/home/workflows" />}
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <p
              style={{
                color: '#333333',
                fontSize: '22px',
                fontWeight: '500',
                marginLeft: '1.2rem',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}>
              Task <InfoOutlined style={{height: '20px', width: '20px', position: 'relative', top: '-0.05rem', color: 'blue'}} />
            </p>
            <img
              onClick={() => {
                this.props.openTaskModal(true);
              }}
              src={AddButton}
              alt="Add"
              style={{marginTop: '0.1rem', width: '1.3rem', marginLeft: ' 1rem', cursor: 'pointer'}}
            />
          </div>
          <div className="topbar__right">
            <TopbarProfile companyId={companyId} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.task,
  }),
  {
    openTaskModal,
  },
)(Topbar);
