import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';

import OnBoardWatch from '../../../shared/fpComponents/onBoardWatch';
import TopbarProfile from '../common/TopbarProfile';
import TopbarSidebarButton from '../common/TopbarSidebarButton';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const {changeMobileSidebarVisibility, changeSidebarVisibility, collapsed, companyId} = this.props;
    const {buffer_web, buffer_mobile, isTrial, trialUpto} = this.props.globalAccess;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {collapsed ? <Link className="topbar__logo1" to="/home/workflows" /> : <Link className="topbar__logo" to="/home/workflows" />}
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <h5 className={'fp-text-sub-heading-1'} style={{cursor: 'pointer', color: '#373737', marginTop: 'auto', marginBottom: 'auto'}}>
              Dashboard{' '}
            </h5>

            <OnBoardWatch page="dashboard" companyId={companyId} />
          </div>
          <div className="topbar__right" style={{width: '50%', justifyContent: 'flex-end'}}>
            {isTrial ? (
              <div className="trialmsg"> {trialUpto} Days Left On Trial </div>
            ) : buffer_web ? (
              <marquee className="buffer-alert"> The Application will be inactive in {buffer_web} days due to overdue payment </marquee>
            ) : (
              ''
            )}

            {/* <div style={{ display: 'flex', paddingTop: '10px', cursor: 'pointer' }} className="fp-text-label">
              <div style={{ paddingTop: '11px', textAlign: 'right' }}>{moment().format('YYYY-MM-DD')}</div>
               <div className="livenow" style={{ width: '50px' }}>
                <div></div>
              </div> 
            </div> */}
            <TopbarProfile companyId={companyId} />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;

// <span style={{margin: '1rem 2rem', position: 'absolute', right: '120px' }}>
// <CalendarTodayIcon /> {moment().format("YYYY-MM-DD")}
//  </span>
