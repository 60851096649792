import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';

export default function FpButton(props) {
  const getClassNames = () => {
    let classes = 'fp-btn ';
    switch (props.type) {
      case 'secondary':
        classes += ' secondary-style';
        break;
      case 'danger':
        classes += ' danger-style';
        break;
      case 'info':
        classes += ' info-style';
        break;
      case 'outlined':
        classes += ' outlined-style';
        break;
      case 'mini-primary':
        classes += ' mini-primary';
        break;
      case 'mini-default':
        classes += ' mini-default';
        break;
      default:
        classes += ' primary-style';
    }
    return classes;
  };

  return (
    <Button variant="contained" size={props.size} className={getClassNames()} onClick={props.onClick}>
      {' '}
      {props.children}{' '}
    </Button>
  );
}

FpButton.PropTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  size: PropTypes.string,
};

FpButton.defaultProps = {
  onClick: () => console.log('clicked FPButton'),
  type: 'primary',
  size: 'medium',
};
