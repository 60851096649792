import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {makeStyles} from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as FileSaver from 'file-saver';
import ArrowDropDownIcon from 'mdi-react/ArrowDropDownIcon';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import * as XLSX from 'xlsx';

import {setCreateUserModal, setTeamType} from '../../../redux/actions/team';
import {COLORS} from '../../../shared/colors';
import TopbarProfile from '../common/TopbarProfile';
import TopbarSidebarButton from '../common/TopbarSidebarButton';
import TopBarName from './TopBarName';

const jwt = require('jsonwebtoken');
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const options1 = ['Import Users', 'Add By Form', 'Import from Main group'];
const options2 = ['Import Users', 'Add By Form'];

const styles = makeStyles((theme) => ({
  button: {
    background: '#373737',
    borderColor: '#373737',
  },
  rootButton: {
    padding: '6px 10px',
    color: '#2b2b2b',
    fontWeight: 'light',

    background: 'white',
    borderColor: '#4CAF50',

    '&:hover': {
      background: 'white',
      borderColor: '#4CAF50',
      color: 'black',
    },
  },
}));

class Topbar extends Component {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef(null);
  }
  state = {
    open: false,
    selectedIndex: 0,
  };

  toggle = () => {
    if (this.props.teamType) {
      this.props.setTeamType('');
    }
  };

  getData = (data) => {
    const temp = data.map((data1) => {
      return {
        'User Id': data1.userid,
        'Agent Name': data1.name,
        password: this.decodeJWT(data1.password),
      };
    });
    return XLSX.utils.json_to_sheet(temp);
  };

  decodeJWT = (token) => {
    try {
      let data = jwt.verify(token, 'fpsecrets210520');
      return data.password;
    } catch (e) {
      return token;
    }
  };

  exportToCSV = () => {
    const SheetNames = [];
    const Sheets = {};

    SheetNames.push(this.props.teamType);
    Sheets[this.props.teamType] = this.getData(this.props.userData);

    const wb = {Sheets, SheetNames};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const excelData = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(excelData, this.props.teamType + fileExtension);
  };

  handleMenuItemClick = (event, index) => {
    this.setState({
      selectedIndex: index,
      open: false,
    });
  };

  handleToggle = () => {
    this.setState((prevState) => ({open: !prevState.open}));
  };

  onSubmit = (e) => {
    console.log(options1[this.state.selectedIndex]);
    this.props.setCreateUserModal(options1[this.state.selectedIndex]);
  };

  handleClose = (event) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({
      open: false,
    });
  };

  render() {
    const {changeMobileSidebarVisibility, changeSidebarVisibility, collapsed, userType, teamType, companyId, classes} = this.props;
    const {buffer_web, buffer_mobile, isTrial, trialUpto} = this.props.globalAccess;
    const {selectedIndex, open} = this.state;

    return (
      <div className="topbar" style={{boxShadow: 'none'}}>
        <div className="topbar__wrapper">
          <div className="topbar__left" style={{width: '60% !important'}}>
            {collapsed ? <Link className="topbar__logo1" to="/home/workflows" /> : <Link className="topbar__logo" to="/home/workflows" />}

            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <h5
              className={'fp-text-sub-heading-1'}
              style={{
                color: '#333333',
                marginTop: 'auto',
                marginBottom: 'auto',
                cursor: this.props.teamType ? 'pointer' : 'auto',
              }}
              onClick={this.toggle}>
              {userType}
            </h5>
            {!!this.props.teamType && (
              <>
                <ArrowForwardIosIcon style={{width: '12px', marginTop: 'auto', marginBottom: 'auto', marginLeft: 8, marginRight: 8}} />
              </>
            )}
            {teamType ? <TopBarName {...this.props} /> : null}
          </div>

          <div className="topbar__right" style={{height: '60px'}}>
            {isTrial ? (
              <div className="trialmsg"> {trialUpto} Days Left On Trial </div>
            ) : buffer_web ? (
              <marquee className="buffer-alert"> The Application will be inactive in {buffer_web} days due to overdue payment </marquee>
            ) : (
              ''
            )}
            {teamType ? (
              <div className="noti-wrapper">
                <div>
                  <IconButton style={{color: COLORS.PRIMARYCOLOR}} aria-label="Download Data" onClick={this.exportToCSV}>
                    <CloudDownloadIcon />
                  </IconButton>
                </div>

                <ButtonGroup aria-label="contined primary split button group" color="default" className="fp-btn " ref={this.anchorRef}>
                  <Button variant="outlined" className="outlined-style" disableElevation onClick={this.onSubmit}>
                    {options1[selectedIndex]}
                  </Button>
                  <Button
                    disableElevation
                    variant="outlined"
                    size="small"
                    className="outlined-style"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={this.handleToggle}>
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
                  {({TransitionProps, placement}) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <MenuList id="split-button-menu">
                            {teamType !== 'Main User Group'
                              ? options1.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    selected={selectedIndex != undefined ? index === selectedIndex : 0}
                                    onClick={(event) => this.handleMenuItemClick(event, index)}>
                                    {option}
                                  </MenuItem>
                                ))
                              : options2.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    selected={selectedIndex != undefined ? index === selectedIndex : 0}
                                    onClick={(event) => this.handleMenuItemClick(event, index)}>
                                    {option}
                                  </MenuItem>
                                ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            ) : (
              <TopbarProfile companyId={companyId} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userType: state.team.userType,
  teamType: state.team.teamType,
  teams: state.team.teams,
  userData: state.team.userData,
  globalAccess: state.global.globalAccess,
});

const mapDispatchToProps = {
  setTeamType,
  setCreateUserModal,
};

const wrappedComponent = connect(mapStateToProps, mapDispatchToProps)(Topbar);

// export default withStyles(styles, {withTheme: true})(wrappedComponent);
export default wrappedComponent;
