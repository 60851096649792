import {TOGGLE_TASK_MODAL} from '../actions/types';

const initialState = {
  openModal: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_TASK_MODAL:
      return {...state, openModal: action.toggle};
    default:
      return state;
  }
}
