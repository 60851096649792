import {SET_PROFILE, SET_TRIAL_LEFT, SET_BUFFER_DUE} from '../actions/types';

const initialState = {
  profileData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profileData: action.data,
      };
    default:
      return state;
  }
}
