import {applyMiddleware, combineReducers, createStore} from 'redux';
import {reducer as reduxFormReducer} from 'redux-form';
import thunk from 'redux-thunk';

import {
  createReducer,
  DataSetDetailReducer,
  DataSetReducer,
  Global,
  LocationTrackerReducer,
  NotiStackReducer,
  PdfReducer,
  profileReducer,
  Reports,
  responseReducer,
  rtlReducer,
  sidebarReducer,
  TaskReducer,
  Team,
  themeReducer,
  WorkFlowReducer,
} from '../../redux/reducers';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  createTab: createReducer,
  dataSet: DataSetReducer,
  dataSetDetail: DataSetDetailReducer,
  task: TaskReducer,
  workflow: WorkFlowReducer,
  global: Global,
  team: Team,
  response: responseReducer,
  profile: profileReducer,
  locationTracker: LocationTrackerReducer,
  pdf: PdfReducer,
  notiStack: NotiStackReducer,
  reports: Reports,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(reducer, initialState, applyMiddleware(...middleware));

export default store;
