import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import swal from 'sweetalert';
import XLSX from 'xlsx';

import {handleDataSet, setDatasetMetadata} from '../../../redux/actions/dataSetDetail';
import API from '../../../shared/api';
import ApiUrl from '../../../shared/apiURL';
import {COLORS} from '../../../shared/colors';
import {FpModal} from '../../../shared/fpComponents';
import FpButton from '../../../shared/fpComponents/buttons';
import TopBarName from './TopBarName';

// import SubmitButton from './ExportButton';

// Formatting
String.prototype.capitalize = function () {
  var result = this.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      taskNameClick: false,
      openModal: false,
      file: [],
      retainRows: true,
      importing: false,
      invalid: false,
    };
    this.fileOnChange = this.fileOnChange.bind(this);
  }

  getCleanState = () => {
    return {
      taskNameClick: false,
      openModal: false,
      file: [],
      retainRows: true,
      importing: false,
    };
  };

  fileOnChange = (e) => {
    this.setState({invalid: false});
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    const scope = this;
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, {type: 'binary'});
      console.log(' sheets ', readedData.SheetNames);
      if (readedData.SheetNames.length > 1) {
        scope.setState({invalid: true});
        return swal('Error', 'Multiple sheets excel cannot be uploaded');
      }
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      if (!Object.keys(ws).length) {
        scope.setState({invalid: true});
        return swal('Error', 'Cannot upload empty data');
      }
    };
    if (f) {
      reader.readAsBinaryString(f);
    }
    this.setState({
      file: files,
    });
  };

  uploadData = async () => {
    const {headersType, color, icon} = this.props;
    let formdata = new FormData();
    formdata.append('file', this.state.file[0]);
    formdata.append('tableName', this.props.datasetName);
    formdata.append('headersType', JSON.stringify(headersType));
    formdata.append('color', color);
    formdata.append('icon', icon);
    formdata.append('importflag', true);
    formdata.append('retain', this.state.retainRows);
    const response = await API.doUpload(formdata, ApiUrl.dataSetUpload);
    if (response) {
      if (response.status === 'success') {
        swal('Success', 'Dataset imported successfully', 'success');
        this.getMetaData();
        // this.getDataSet();
        this.toggle();
        this.setState({file: []});
      } else if (response.status === 'failed') {
        swal('Error', response.message);
      } else {
        swal('Error', 'dataset upload failed');
      }
    }
    this.setState({
      importing: false,
    });
  };

  ImportData = async () => {
    if (this.state.file.length) {
      this.setState(
        {
          importing: true,
        },
        this.uploadData,
      );
    } else {
      alert('Please upload the file');
    }
  };

  getMetaData = async () => {
    const response = await API.doFetch('POST', {tableName: this.props.tableName}, ApiUrl.dataSetDetail);
    if (response) {
      let Headers = response.headers.map((x) => x);
      if (!this.state.once) {
        this.setState({once: true});
      }
      this.props.setDatasetMetadata({
        datasetName: response.tablename,
        totalPages: response.count,
        headers: Headers,
        headersType: response.headersType
          ? response.headersType
          : response.headers.map((h) => {
              return {name: h, type: 'text'};
            }),
        color: response.color,
        icon: response.icon,
        canEdit: response.canEdit,
        changeListener: this.props.changeListener + 1,
      });
    }
  };

  getDataSet = async () => {
    const response = await API.doFetch('POST', {tableName: this.props.tableName, limit: 20, offset: 0}, ApiUrl.dataSetData);
    if (response) {
      this.props.handleDataSet(response);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.tableName !== nextProps.tableName) {
      this.setState(
        {
          ...this.getCleanState(),
        },
        () => {
          this.getMetaData();
          // this.getDataSet();
        },
      );
    } else if (!this.state.once) {
      this.getMetaData();
      // this.getDataSet();
    }
  }

  componentDidMount = () => {
    this.getMetaData();
    // this.getDataSet();
  };

  openModal = () => {
    this.setState({
      openModal: true,
    });
  };

  toggle = () => {
    this.setState({
      openModal: false,
    });
  };

  toggleRetainRows = (e) => {
    this.setState({
      retainRows: e.target.checked,
    });
  };

  downloadSample = () => {
    let headers = this.props.headersType.map((d) => d.name);
    let data = headers.map((d) => 'data');
    let csvArray = [headers, data];
    let csvContent = 'data:text/csv;charset=utf-8,' + csvArray.map((e) => e.join(',')).join('\n');

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${this.props.datasetName ? this.props.datasetName.capitalize() : sample}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
  };

  downloadData = () => {
    let url = `${ApiUrl.downloadDataset}?tableName=${this.props.tableName}&token=${localStorage.getItem('fp-token')}`;
    var a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  render() {
    const {datasetName, changeMobileSidebarVisibility, changeSidebarVisibility, companyId} = this.props;
    const {openModal, file, retainRows, importing} = this.state;
    return (
      <div className="topbar" key={'topbar11'}>
        <Modal isOpen={openModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <p style={{fontSize: '18px', fontWeight: '500'}}>Import Dataset</p>
          </ModalHeader>
          <ModalBody className="theme-light">
            <Button onClick={this.downloadSample} variant="contained" color="primary">
              {' '}
              Download Sample{' '}
            </Button>
            <hr />

            <input
              type="file"
              onChange={this.fileOnChange}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />

            <div>
              <Checkbox className="checkbox_dataset" color="primary" checked={retainRows} onChange={this.toggleRetainRows} />
              <span>Retain Existing Rows</span>
            </div>
            <div style={{textAlign: 'center', marginTop: '2rem'}}>
              {importing ? (
                <Button disabled={true} style={{height: '35px', marginTop: '0.8rem'}} variant="outlined">
                  Importing...
                </Button>
              ) : (
                <Button
                  disabled={this.state.invalid}
                  onClick={this.ImportData}
                  style={{height: '35px', marginTop: '0.8rem'}}
                  variant="outlined">
                  Import
                </Button>
              )}
            </div>
          </ModalBody>
        </Modal>
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <Link className="topbar__logo1" to={`/${companyId}/home/dataset`} />
            <h5
              onClick={() => this.props.history.push(`/${companyId}/home/dataset`)}
              className={'fp-text-sub-heading'}
              style={{
                cursor: 'pointer',
                color: '#373737',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '1rem',
              }}>
              Dataset
            </h5>
            <ArrowForwardIosIcon style={{width: '12px', marginTop: 'auto', marginBottom: 'auto', marginLeft: 8, marginRight: 8}} />
            <TopBarName name={datasetName} companyId={companyId} excelList={this.props.global.globalDataset || []} />
          </div>
          <div className="topbar__right" style={{height: '60px'}}>
            <div className="noti-wrapper">
              <div>
                <IconButton style={{color: COLORS.PRIMARYCOLOR}} aria-label="Download Data" onClick={this.downloadData}>
                  <CloudDownloadIcon />
                </IconButton>
                {/* <SubmitButton tableName={this.props.tableName} history={this.props.history} /> */}
              </div>

              {this.props.canEdit && (
                <FpModal
                  title="Import data for dataset"
                  customButton={
                    <FpButton type="outlined">
                      Import Data <PublishOutlinedIcon fontSize="small" />
                    </FpButton>
                  }
                  btnLabel=" Import Data "
                  showCancel={false}
                  okLabel="Import"
                  onOk={this.ImportData}>
                  <FpButton onClick={this.downloadSample} size="small" type="info">
                    {' '}
                    Sample Format &nbsp; <CloudDownloadIcon />{' '}
                  </FpButton>
                  <abbr title="Use this format to upload columns of data">
                    <InfoOutlinedIcon fontSize="small" />
                  </abbr>
                  <hr />
                  <div style={{padding: '10px'}}>
                    <input
                      type="file"
                      onChange={this.fileOnChange}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </div>
                  <div>
                    <Checkbox className="checkbox_dataset" color="primary" checked={retainRows} onChange={this.toggleRetainRows} />
                    <span style={{fontSize: '15px', position: 'relative', left: '-0.3rem', top: '0.15rem'}}>Retain Existing Rows</span>
                  </div>
                </FpModal>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({dataSetDetail: {datasetName, headersType, color, icon, changeListener, canEdit}, global}) => ({
    datasetName,
    headersType,
    global,
    color,
    icon,
    changeListener,
    canEdit,
  }),
  {
    setDatasetMetadata,
    handleDataSet,
  },
)(Topbar);
