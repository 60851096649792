import {SET_CREATE_USER_MODAL, SET_TEAM_TYPE, SET_TEAM_USER_DATA, SET_TEAMS, SET_USER_MANAGEMENT_TYPE} from '../actions/types';

const initialState = {
  userType: 'Users',
  teamType: '',
  teams: [],
  userData: [],
  modalType: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_MANAGEMENT_TYPE:
      return {...state, userType: action.value};
    case SET_TEAM_TYPE:
      return {...state, teamType: action.value};
    case SET_TEAMS:
      return {...state, teams: action.value};
    case SET_CREATE_USER_MODAL:
      return {...state, modalType: action.value};
    case SET_TEAM_USER_DATA:
      return {...state, userData: action.value};
    default:
      return state;
  }
}
