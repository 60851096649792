import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';

import TopbarProfile from '../common/TopbarProfile';
import TopbarSidebarButton from '../common/TopbarSidebarButton';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.taskName !== this.props.taskName) {
      if (nextProps.taskName) {
        if (!this.props.collapsed) {
          this.props.changeSidebarVisibility();
        }
      } else {
        if (this.props.collapsed) {
          this.props.changeSidebarVisibility();
        }
      }
    }
  };

  render() {
    const {changeMobileSidebarVisibility, changeSidebarVisibility, collapsed, companyId} = this.props;
    const {buffer_web, buffer_mobile, isTrial, trialUpto} = this.props.globalAccess;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {collapsed ? <Link className="topbar__logo1" to="/home/workflows" /> : <Link className="topbar__logo" to="/home/workflows" />}

            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <h5 className={'fp-text-sub-heading-1'} style={{cursor: 'pointer', color: '#373737', marginTop: 'auto', marginBottom: 'auto'}}>
              Reports Detail{' '}
            </h5>
          </div>
          <div className="topbar__right">
            {isTrial ? (
              <div className="trialmsg"> {trialUpto} Days Left On Trial </div>
            ) : buffer_mobile ? (
              <marquee className="buffer-alert"> Mobile App deactivates in {buffer_mobile} days! </marquee>
            ) : buffer_web ? (
              <marquee className="buffer-alert"> Web app deactivates in {buffer_web} days! </marquee>
            ) : (
              ''
            )}
            <TopbarProfile companyId={companyId} />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
