import React, {PureComponent} from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

class DropZoneField extends PureComponent {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(file) {
    file.map((fl) =>
      Object.assign(fl, {
        preview: URL.createObjectURL(fl),
      }),
    );
    this.props.onChange(file);
  }

  removeFile(index, e) {
    this.props.onChange([]);
  }

  render() {
    const {customHeight, name, file} = this.props;
    console.log(file);

    return (
      <div className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''}`}>
        <Dropzone
          accept="image/jpeg, image/png"
          name={name}
          multiple={false}
          onDrop={(fileToUpload) => {
            this.onDrop(fileToUpload);
          }}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} className="dropzone__input">
              {(!file || file.length === 0) && (
                <div className="dropzone__drop-here">
                  <span className="lnr lnr-upload" /> Drop file here to upload
                </div>
              )}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        {file && Array.isArray(file) && file.length > 0 && (
          <aside className="dropzone__img">
            <img src={file[0].preview} alt="drop-img" />
            <p className="dropzone__img-name">{file[0].name}</p>
            <button className="dropzone__img-delete" type="button" onClick={(e) => this.removeFile(0, e)}>
              Remove
            </button>
          </aside>
        )}
      </div>
    );
  }
}

const renderDropZoneField = (props) => {
  const {customHeight, onChange, file} = props;
  return <DropZoneField onChange={onChange} customHeight={customHeight} file={file} />;
};

export default renderDropZoneField;
