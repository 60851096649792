import TextField from '@material-ui/core/TextField';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SettingsIcon from '@material-ui/icons/Settings';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {openTaskSettings, updateTaskName} from '../../../redux/actions/createtabActions';
import API from '../../../shared/api';
import APIUrl from '../../../shared/apiURL';
import {TaskToBackend} from '../../../shared/logic';
import SubmitButton from './TopbarProfile';

class Topbar extends PureComponent {
  state = {
    taskNameClick: false,
    newTask: false,
  };

  componentDidMount = () => {
    let paths = window.location.pathname.split('/');
    if (paths[3] === 'workflow' && paths[4]) this.getWorkflowState(paths[4]);
    if (paths[5] === 'tasks' && paths[6] === 'new')
      this.setState({
        newTask: true,
      });
  };

  componentDidUpdate = () => {
    let paths = window.location.pathname.split('/');
    if (this.state.newTask && paths[6] !== 'new') {
      this.setState({
        newTask: false,
      });
    }
  };

  getWorkflowState = (workflowId) => {
    if (workflowId) {
      API.doFetch('POST', {workflowId}, APIUrl.getWorkflow)
        .then((response) => {
          if (response.workflow && response.workflow.name) {
            this.setState({
              name: response.workflow.name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <Link className="topbar__logo1" to="/home/workflows" />

            <h5
              style={{
                fontSize: '21px',
                fontWeight: '500',
                color: '#373737',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => window.history.back()}>
              {this.state.name || 'workflow'}
            </h5>
            {!this.state.newTask && (
              <React.Fragment>
                <ArrowForwardIosIcon style={{width: '18px', marginTop: 'auto', marginBottom: 'auto'}} />
                <div style={{lineHeight: '20px', marginLeft: 10, marginTop: 'auto', marginBottom: 'auto'}} className="taskname-input-div">
                  {this.state.newTask ? (
                    <span style={{fontSize: 15}}>New Task</span>
                  ) : (
                    <TextField
                      id="taskname"
                      label="Task Name"
                      variant="outlined"
                      className="taskname-input"
                      size="small"
                      value={this.props.taskName}
                      onChange={(event) => this.props.updateTaskName(event.target.value)}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          </div>

          {!this.state.newTask && (
            <div className="topbar__right" style={{height: '60px'}}>
              <SettingsIcon onClick={this.props.openTaskSettings} style={{width: 80, cursor: 'pointer', fontSize: 30, marginTop: 15}} />
              <div style={{marginRight: 10, marginTop: 'auto', marginBottom: 'auto'}}>
                <SubmitButton TaskToBackend={TaskToBackend} openTaskSettings={this.props.openTaskSettings} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.createTab,
  }),
  {
    updateTaskName,
    openTaskSettings,
  },
)(Topbar);
