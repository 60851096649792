import {useSnackbar} from 'notistack';
import React from 'react';
import {connect} from 'react-redux';

import {removeSnackbar} from '../../redux/actions/notistackActions';

/** 
 * Usage 
 * Import enqueueSnackbar from actions and call like sample below
  this.props.enqueueSnackbar({
  message:'Live share link copied to clipboard',
  options: { 
    key:'liveShare',
    variant:'success',
    preventDuplicate: true,
  }
});
**/

let displayed = [];

function Notifier({notifications, removeSnackbar}) {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({key, message, options = {}, dismissed = false}) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          removeSnackbar(myKey);
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar]);

  return null;
}

export default connect(
  ({notiStack: {notifications}}) => ({
    notifications,
  }),
  {removeSnackbar},
)(Notifier);
