import PropTypes from 'prop-types';
import React from 'react';
import {Fragment} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import Button from './buttons';

export default class FpModal extends React.Component {
  static PropTypes = {
    title: PropTypes.string.isRequired,
    btnLabel: PropTypes.string.isRequired,
    customButton: PropTypes.node,
    modelContent: PropTypes.isRequired,
    showCancel: PropTypes.bool,
    showOk: PropTypes.bool,
    okLabel: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
  };

  static defaultProps = {
    title: 'Title',
    btnLabel: 'Show modal',
    modelContent: 'Model content ',
    showOk: true,
    showCancel: true,
    okLabel: 'confirm',
  };

  state = {
    modal: false,
  };

  toggle = () => {
    this.setState((prevState) => ({modal: !prevState.modal}));
  };

  onOk = () => {
    this.props.onOk();
    this.toggle();
  };

  onCancel = () => {
    this.props.onCancel();
    this.toggle();
  };

  render() {
    const {btnLabel, title, modelContent, showOk, showCancel, okLabel, customButton} = this.props;
    const {modal} = this.state;

    return (
      <div>
        {customButton ? (
          <span onClick={this.toggle}>{customButton}</span>
        ) : (
          <Button type="outlined" onClick={this.toggle}>
            {btnLabel}
          </Button>
        )}
        <Modal
          isOpen={modal}
          modalTransition={{timeout: 700}}
          backdropTransition={{timeout: 1300}}
          toggle={this.toggle}
          className="fp-modal">
          <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
          <ModalBody>{this.props.children}</ModalBody>
          <ModalFooter>
            {showOk ? (
              <Button type="primary" onClick={this.onOk}>
                {okLabel}
              </Button>
            ) : (
              ''
            )}
            {showCancel ? (
              <Button type="secondary" onClick={this.onCancel}>
                Close
              </Button>
            ) : (
              ''
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
