import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal, ModalBody} from 'reactstrap';

import {
  setGlobalAccess,
  setGlobalDataset,
  setGlobalPdf,
  setGlobalTask,
  setGlobalWorkflow,
  setNotifications,
  setOnboardStatus,
} from '../../redux/actions/global';
import {setProfile} from '../../redux/actions/profileActions';
import {changeMobileSidebarVisibility, changeSidebarVisibility} from '../../redux/actions/sidebarActions';
import {changeThemeToDark, changeThemeToLight} from '../../redux/actions/themeActions';
import API from '../../shared/api';
import ApiUrl from '../../shared/apiURL';
import Upload from '../../shared/components/upload';
import {SidebarProps} from '../../shared/prop-types/ReducerProps';
import TopBar from './common/TopBar';
import CreatePdfTopBar from './createPdfTopBar/Topbar';
import CreateTopBar from './createtopbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import TopBarDashboard from './topBarDashboard/Topbar';
import TopBarDataset from './topbarDataSet/Topbar';
import TopBarDatasetDetail from './topbarDataSetDetail/Topbar';
import TopBarLocation from './topBarLocation/Topbar';
import TopbarManagerPermission from './topbarManagerPermission/Topbar';
import TopBarProfile from './topBarProfile/Topbar';
import TopBarReportDetail from './topbarReportDetail/Topbar';
import TopBarReports from './topbarReports/Topbar';
import TopBarResponse from './topbarResponse/TopBar';
import TaskTobBar from './topbarTask/Topbar';
import TopBarTeam from './topbarTeam/Topbar';
import WorkFlowList from './topbarWorkflowList/Topbar';

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
  };

  state = {
    modal: false,
    userType: '',
    companyId: '',
    webActive: true,
  };

  changeSidebarVisibility = () => {
    const {dispatch} = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const {dispatch} = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    const {dispatch} = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const {dispatch} = this.props;
    dispatch(changeThemeToLight());
  };

  pullProfile = async () => {
    const {dispatch} = this.props;
    const getProfile = await API.doFetch('POST', {}, ApiUrl.getProfile);
    if (getProfile) {
      localStorage.setItem('fp-profile', JSON.stringify(getProfile));
      dispatch(setProfile(getProfile));
      this.setState({modal: false});
    }
  };

  componentDidMount = async () => {
    this.checkPath();

    const firstTimeUser = await API.getFirstTimeUser();
    if (firstTimeUser === 'Admin' || firstTimeUser === 'Manager') {
      this.setState({
        modal: true,
        userType: firstTimeUser,
      });
      await API.setFirstTimeUser(false);
    }
    const {dispatch} = this.props;

    let access = await API.doFetch('POST', {}, ApiUrl.TokenValidity);
    if (access && access.data) {
      const activeStatus = {...access.data, webActive: true, isTrial: false}; // ssn
      dispatch(setGlobalAccess(activeStatus));
      if (activeStatus.webActive === false) {
        this.props.history.push(`/${this.props.match.params.companyId}/disabled`);
      }
      // if (access.data.isTrial) {
      //   this.setState({ modal: true })
      // }
    }

    const getProfile = await API.doFetch('POST', {}, ApiUrl.getProfile);
    if (getProfile) {
      const {companyId, webActive} = getProfile;
      this.setState({companyId, webActive});
      localStorage.setItem('fp-profile', JSON.stringify(getProfile));
      dispatch(setProfile(getProfile));
    }

    let response = await API.doFetch('POST', {}, `${ApiUrl.API_URL}/V1/task/createTabInit`);
    if (response) {
      dispatch(setGlobalTask(response.taskList));
      dispatch(setGlobalDataset(response.excelList));
      dispatch(setGlobalPdf(response.pdfList));
      dispatch(setOnboardStatus(response.onBoardStatus));
    }

    response = await API.doFetch('POST', {}, ApiUrl.workFlowList);
    if (response) {
      dispatch(setGlobalWorkflow(response.workflows));
    }

    let notifications = await API.doFetch('POST', {}, ApiUrl.getNotifications);
    if (notifications && notifications.success) {
      dispatch(setNotifications(notifications.data));
    }
  };

  componentDidUpdate() {
    this.checkPath();
  }

  checkPath = async () => {
    const profile = await API.getProfile();
    const {companyId} = profile;

    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${companyId}/home/tasks/` + '.+$');
    var workflowpattern1 = new RegExp('^/' + `${companyId}/home/workflow/` + '.+$');
    var workflowpattern = new RegExp('^/' + `${companyId}/home/workflow` + '$');
    var taskpattern = new RegExp('^/' + `${companyId}/home/tasks` + '$');
    var reportsPage = new RegExp('^/' + `${companyId}/home/reports` + '$');
    var dataSetPattern = new RegExp('^/' + `${companyId}/home/dataset/` + '.+$');
    var patternDataset = new RegExp('^/' + `${companyId}/home/task-dataset/` + '.+$');

    const {sidebar} = this.props;
    if (
      pattern.test(href) ||
      workflowpattern.test(href) ||
      workflowpattern1.test(href) ||
      this.isResponseesPage() ||
      this.isLocationTrackingPage() ||
      this.isProfilePage() ||
      patternDataset.test(href)
    ) {
      if (sidebar.collapse === false) {
        this.changeSidebarVisibility();
      }
    } else if (taskpattern.test(href)) {
      if (sidebar.collapse === true) {
        this.changeSidebarVisibility();
      }
    }
    if (dataSetPattern.test(href)) {
      if (sidebar.collapse === false) {
        this.changeSidebarVisibility();
      }
    }
  };

  isTaskPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/tasks`);

    return pattern.test(href);
  };

  isReportPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/reports`);
    return pattern.test(href);
  };

  isReportDetailPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/reports/` + '.+$');
    return pattern.test(href);
  };

  isPdfPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/tasks`);

    return pattern.test(href);
  };

  isWorkFlowPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/workflow/`);

    return pattern.test(href) && !href.includes('tasks');
  };

  isWorkFlowListPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/workflows`);

    return pattern.test(href);
  };

  isCreateTaskPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/workflow/` + '.+$');

    return pattern.test(href) && href.includes('tasks');
  };

  isCreatePdfPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/workflow/` + '.+$');

    return pattern.test(href) && href.includes('pdf');
  };

  isManagerPermission = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/manager/permissions/` + '.+$');

    return pattern.test(href);
  };

  isDatasetToTaskPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/task-dataset/` + '.+$');

    return pattern.test(href);
  };

  isDataSetPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/dataset`);

    return pattern.test(href);
  };

  isTeamPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/team`);

    return pattern.test(href);
  };

  isProfilePage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/profile`);

    return pattern.test(href);
  };

  isDataSetDetailPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/dataset/` + '.+$');

    return pattern.test(href);
  };

  isResponseesPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/responses`);

    return pattern.test(href);
  };

  isDashboardPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/dashboard`);

    return pattern.test(href);
  };

  isLocationTrackingPage = () => {
    let href = this.props.location.pathname;
    var pattern = new RegExp('^/' + `${this.state.companyId}/home/location-tracking`);

    return pattern.test(href);
  };

  toggle = () => {
    const {modal} = this.state;
    this.setState({
      modal: !modal,
    });
  };

  render() {
    const {sidebar, globalAccess} = this.props;
    const {modal, companyId} = this.state;

    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
    });

    if (this.state.webActive === false && !this.isProfilePage() && companyId) {
      return <Redirect to={`/${companyId}/home/profile`} />;
    }

    return (
      <div className={layoutClass}>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalBody className="theme-light">
            <Upload toggle={this.toggle} userType={this.state.userType} pullProfile={this.pullProfile} />
          </ModalBody>
        </Modal>
        {/* {
          globalAccess && globalAccess.isTrial &&
          <span className="trial-block">
            {`Trial ends in ${globalAccess.trialUpto || 0} days, Please setup your payment in Profile page`}
          </span>
        }  */}
        {this.isCreateTaskPage() ? (
          <CreateTopBar
            location={this.props.location.pathname}
            collapsed={this.props.sidebar.collapse}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            companyId={this.state.companyId}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isCreatePdfPage() ? (
          <CreatePdfTopBar
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isDatasetToTaskPage() ? null : this.isTaskPage() ? (
          <TaskTobBar
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isWorkFlowListPage() ? (
          <WorkFlowList
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
            history={this.props.history}
          />
        ) : this.isDataSetDetailPage() ? (
          <TopBarDatasetDetail
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            tableName={this.props.location.pathname.substring(companyId.length + 15)}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
            history={this.props.history}
          />
        ) : this.isDataSetPage() ? (
          <TopBarDataset
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isProfilePage() ? (
          <TopBarProfile
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isWorkFlowPage() ? null : this.isResponseesPage() ? (
          <TopBarResponse
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isDashboardPage() ? (
          <TopBarDashboard
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isLocationTrackingPage() ? (
          <TopBarLocation
            {...this.props}
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isReportDetailPage() ? (
          <TopBarReportDetail
            {...this.props}
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isReportPage() ? (
          <TopBarReports
            {...this.props}
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isTeamPage() ? (
          <TopBarTeam
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : this.isManagerPermission() ? (
          <TopbarManagerPermission
            isManagerPermission={this.isManagerPermission()}
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        ) : (
          <TopBar
            collapsed={this.props.sidebar.collapse}
            companyId={this.state.companyId}
            pageTitle={'Field Proxy'}
            globalAccess={this.props.globalAccess}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        )}
        <Sidebar
          sidebar={sidebar}
          companyId={this.state.companyId}
          pathname={this.props.location.pathname}
          workflowPage={this.isWorkFlowPage() || this.isTeamPage() ? true : false}
          datasetToTask={this.isDatasetToTaskPage()}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      </div>
    );
  }
}

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
    globalAccess: state.global.globalAccess,
  }))(Layout),
);
