import {SET_CREATE_USER_MODAL, SET_TEAM_TYPE, SET_TEAM_USER_DATA, SET_TEAMS, SET_USER_MANAGEMENT_TYPE} from './types';

export const setUserManagementType = (value) => (dispatch) => {
  dispatch({
    type: SET_USER_MANAGEMENT_TYPE,
    value,
  });
};

export const setTeamType = (value) => (dispatch) => {
  dispatch({
    type: SET_TEAM_TYPE,
    value,
  });
};

export const setTeams = (value) => (dispatch) => {
  dispatch({
    type: SET_TEAMS,
    value,
  });
};

export const setCreateUserModal = (value) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_MODAL,
    value,
  });
};

export const setTeamUserData = (value) => (dispatch) => {
  dispatch({
    type: SET_TEAM_USER_DATA,
    value,
  });
};
