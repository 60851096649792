import {
  SET_GLOBAL_ACCESS,
  SET_GLOBAL_DATASET,
  SET_GLOBAL_PDF,
  SET_GLOBAL_TASK,
  SET_GLOBAL_WORKFLOW,
  SET_NOTIFICATIONS,
  SET_ONBOARD_STATUS,
} from '../actions/types';

const initialState = {
  globalTask: [],
  globalWorkflow: [],
  globalDataset: [],
  globalPdf: [],
  globalAccess: {},
  onboardStatus: {},
  notifications: [],
  unReadNotifications: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GLOBAL_TASK:
      return {...state, globalTask: action.globalTask};
    case SET_GLOBAL_WORKFLOW:
      return {...state, globalWorkflow: action.globalWorkflow};
    case SET_GLOBAL_DATASET:
      return {...state, globalDataset: action.globalDataset};
    case SET_GLOBAL_PDF:
      return {...state, globalPdf: action.globalPdf};
    case SET_GLOBAL_ACCESS:
      return {...state, globalAccess: action.globalAccess};
    case SET_ONBOARD_STATUS:
      return {...state, onboardStatus: action.onBoardStatus};
    case SET_NOTIFICATIONS:
      return {...state, notifications: action.notifications, unReadNotifications: action.notifications.findIndex((n) => !n.read) > -1};
    default:
      return state;
  }
}
