import ReactGA from 'react-ga';

import SECRETS from '../shared/secrets.js';

export const initGA = async () => {
  console.log('analytics fired=========', SECRETS.analyticsId);

  ReactGA.initialize(SECRETS.analyticsId, {debug: true, cookieDomain: 'none'});

  let loggedUser = await localStorage.getItem('fp-profile');
  if (loggedUser) {
    let user = JSON.parse(loggedUser);
    if (user && user.firstName) {
      ReactGA.set({
        userId: user.firstName,
        company: user.companyName,
      });
    }
  }
};

export const PageView = (pathname) => {
  ReactGA.pageview(pathname);
};

export const Event = (category, action, label) => {
  console.log('Event called', category, action, label);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
