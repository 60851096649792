import {
  SELECT_PDF_ELEMENT,
  SET_INVOICE_HEADER_DATA,
  SET_INVOICE_BODY_DATA,
  SET_INVOICE_FOOTER_DATA,
  UPDATE_PDF_NAME,
  OPEN_PDF_SETTINGS,
  UPDATE_PDF_SETTINGS,
} from './types';

export const selectElement = (data) => (dispatch) => {
  dispatch({
    type: SELECT_PDF_ELEMENT,
    data,
  });
};

export const setInvoiceHeaderData = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_HEADER_DATA,
    data,
  });
};

export const setInvoiceBodyData = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_BODY_DATA,
    data,
  });
};

export const setInvoiceFooterData = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_FOOTER_DATA,
    data,
  });
};

export const updatePdfName = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PDF_NAME,
    data,
  });
};

export const updatePdfSettings = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PDF_SETTINGS,
    data,
  });
};
export const openPdfSettings = () => (dispatch) => {
  dispatch({
    type: OPEN_PDF_SETTINGS,
  });
};
