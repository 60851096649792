import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {setOnboardStatus} from '../../redux/actions/global';
import {Event} from '../../services';
import Api from '../api';
import ApiUrl from '../apiURL';
import Button from './buttons';

class OnBoardWatch extends React.Component {
  static PropTypes = {
    page: PropTypes.string.isRequired,
    isInBuilt: PropTypes.bool,
  };

  state = {
    visible: false,
  };

  toggle = () => {
    this.setState((prevState) => ({visible: !prevState.visible}));
  };

  onWatched = () => {
    this.toggle();
    let {onboardStatus, page, setOnboardStatus, companyId} = this.props;
    if (!onboardStatus) {
      onboardStatus = {};
    }
    if (!onboardStatus[page]) {
      onboardStatus[page] = {};
    }
    onboardStatus[page].visited = true;
    Event('Onboarding', page, companyId);
    let save = Api.doFetch('POST', {onboardStatus}, ApiUrl.updateOnboardStatus);
    if (save && save.status) {
      setOnboardStatus(onboardStatus);
    }
  };

  getDetails = (page) => {
    switch (page) {
      case 'location-tracking':
        return {
          title: 'Location Tracking',
          videoUrl: 'https://res.cloudinary.com/dkqcqqa1r/video/upload/v1604643388/onboarding/looma_oqdqyz.mp4',
        };
      case 'dashboard':
        return {
          title: 'Dashboard',
          videoUrl: 'https://res.cloudinary.com/dvta5tug5/video/upload/v1606451311/Fieldproxy_-_Dashboard_plzdql.mp4',
        };
      case 'reports':
        return {
          title: 'Reports',
          videoUrl: 'https://res.cloudinary.com/dvta5tug5/video/upload/v1606451790/FP_-_Reports_yme8um.mp4',
        };
      default:
        return {
          title: 'Onboarding Title',
          videoUrl: 'https://res.cloudinary.com/dkqcqqa1r/video/upload/v1604643388/onboarding/looma_oqdqyz.mp4',
        };
    }
  };

  render() {
    const {onboardStatus, page, isInBuilt} = this.props;
    const data = this.getDetails(page);
    return (
      <React.Fragment>
        {isInBuilt ? (
          <span onClick={this.toggle}> click here </span>
        ) : (
          <React.Fragment>
            {!onboardStatus || !onboardStatus[page] || !onboardStatus[page].visited ? (
              <PlayCircleFilledWhiteIcon className="onBoard-icon yet-to-watch" onClick={this.toggle} />
            ) : (
              <PlayCircleFilledWhiteIcon className="onBoard-icon watched" onClick={this.toggle} />
            )}
          </React.Fragment>
        )}
        <Modal
          isOpen={this.state.visible}
          modalTransition={{timeout: 700}}
          backdropTransition={{timeout: 1300}}
          toggle={this.toggle}
          className="onboard-modal">
          <ModalHeader toggle={this.toggle}>{data.title}</ModalHeader>
          <ModalBody>
            <div className="onBoardVideo">
              <video autoplay playsInline id="video-background" controls className="video-play">
                <source src={data.videoUrl} />
              </video>
            </div>
            {this.props.children}
          </ModalBody>
          <ModalFooter>
            <Button type="secondary" onClick={this.onWatched}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(
  ({global: {onboardStatus}}) => ({
    onboardStatus,
  }),
  {setOnboardStatus},
)(OnBoardWatch);
