import {
  COPY_QUESTION,
  DELETE_ADDON,
  DELETE_CURRENT_SUB_INDEX,
  DELETE_QUESTION,
  DELETE_SUB_FORM,
  DELETE_SUB_GROUP,
  DELETE_SUBFORM_OPTION,
  OPEN_TASK_SETTINGS,
  SYNC_ALLOCATIONS,
  SYNC_CHANGES,
  TOGGLE_SETTINGS_VIEW,
  UPDATE_ADDON,
  UPDATE_ADDONS,
  UPDATE_CURRENT_ADDON_INDEX,
  UPDATE_CURRENT_INDEX,
  UPDATE_LOADER,
  UPDATE_MANAGER_PERMISSION,
  UPDATE_MANAGER_PERMISSION_INDEX,
  UPDATE_META_DATA,
  UPDATE_PERMISSION,
  UPDATE_QUESTION,
  UPDATE_QUESTIONS,
  UPDATE_QUESTIONS_DETAILS,
  UPDATE_QUESTIONS_FROM_RESPONSE,
  UPDATE_SUB_FORM,
  UPDATE_SUB_GROUP_QUESTION,
  UPDATE_SUB_INDEX,
  UPDATE_SUBFORM_OPTION,
  UPDATE_SYNC,
  UPDATE_SYNC_SETTINGS,
  UPDATE_TASK_DATA,
  UPDATE_TASK_DESCRIPTION,
  UPDATE_TASK_ID,
  UPDATE_TASK_NAME,
  UPDATE_TASK_QUESTIONS,
  UPDATE_TASK_SETTINGS,
  UPDATE_TASK_STATUS,
} from './types';

export const toggleSettingsView = (value) => (dispatch) => {
  dispatch({
    type: TOGGLE_SETTINGS_VIEW,
    value,
  });
};

export const updateAddOns = (addOns) => (dispatch) => {
  dispatch({
    type: UPDATE_ADDONS,
    addOns,
  });
};

export const updateAddOn = (addOn, index) => (dispatch) => {
  dispatch({
    type: UPDATE_ADDON,
    addOn,
    index,
  });
};

export const deleteAddOn = (index, addOnId) => (dispatch) => {
  dispatch({
    type: DELETE_ADDON,
    index,
    addOnId,
  });
};

export const openTaskSettings = () => (dispatch) => {
  dispatch({
    type: OPEN_TASK_SETTINGS,
  });
};

export const syncChanges = (editedFlag, taskid) => (dispatch) => {
  dispatch({
    type: SYNC_CHANGES,
    editedFlag,
    taskid,
  });
};

export const updateSync = (editedFlag, syncing, editedQuestionsId) => {
  return {
    type: UPDATE_SYNC,
    editedFlag,
    syncing,
    editedQuestionsId,
  };
};

export const updateSyncSettings = (syncing) => {
  return {
    type: UPDATE_SYNC_SETTINGS,
    syncing,
  };
};

export const updateMetaData = (metaData) => {
  return {
    type: UPDATE_META_DATA,
    metaData,
  };
};

export const updateQuestionsDetails = (questionsDetails) => (dispatch) => {
  dispatch({
    type: UPDATE_QUESTIONS_DETAILS,
    questionsDetails,
  });
};

export const updateQuestionsFromResponse = (questions) => (dispatch) => {
  dispatch({
    type: UPDATE_QUESTIONS_FROM_RESPONSE,
    questions,
  });
};

export const updateQuestions = (questions) => (dispatch) => {
  dispatch({
    type: UPDATE_QUESTIONS,
    questions,
  });
};

export const updateQuestion = (question, index) => (dispatch) => {
  dispatch({
    type: UPDATE_QUESTION,
    question,
    index,
  });
};

export const deleteQuestion = (index, questionid) => (dispatch) => {
  dispatch({
    type: DELETE_QUESTION,
    index,
    questionid,
  });
};

export const copyQuestion = (copiedQuestion) => (dispatch) => {
  dispatch({
    type: COPY_QUESTION,
    copiedQuestion,
  });
};

export const updateCurrentIndex = (index) => (dispatch) => {
  //TaskToBackend(true);
  dispatch({
    type: UPDATE_CURRENT_INDEX,
    index,
  });
};

export const updateCurrentAddOnIndex = (index) => (dispatch) => {
  dispatch({
    type: UPDATE_CURRENT_ADDON_INDEX,
    index,
  });
};

export const deleteSubGroup = (questionIndex, index) => (dispatch) => {
  dispatch({
    type: DELETE_SUB_GROUP,
    questionIndex,
    index,
  });
};

export const deleteSubQuestion = (questionIndex, parentIndex, index) => (dispatch) => {
  dispatch({
    type: DELETE_CURRENT_SUB_INDEX,
    questionIndex,
    parentIndex,
    index,
  });
};

export const updateSubQuestion = (question, questionIndex, parentIndex, index) => (dispatch) => {
  dispatch({
    type: UPDATE_SUB_INDEX,
    question,
    questionIndex,
    parentIndex,
    index,
  });
};

export const updateSubGroupOption = (questionIndex, index, value) => (dispatch) => {
  dispatch({
    type: UPDATE_SUB_GROUP_QUESTION,
    questionIndex,
    index,
    value,
  });
};

export const updateTaskData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_TASK_DATA,
    data,
  });
};

export const updateTaskName = (name) => (dispatch) => {
  dispatch({
    type: UPDATE_TASK_NAME,
    name,
  });
};

export const updateTaskDescription = (descriptionObj) => (dispatch) => {
  dispatch({
    type: UPDATE_TASK_DESCRIPTION,
    descriptionObj,
  });
};

export const updateTaskId = (id) => (dispatch) => {
  dispatch({
    type: UPDATE_TASK_ID,
    id,
  });
};

export const updateTaskSettings = (taskid, settings) => (dispatch) => {
  dispatch({
    type: UPDATE_TASK_SETTINGS,
    taskid,
    settings,
  });
};

export const syncAllocations = (taskid, userGroupsSelected) => (dispatch) => {
  dispatch({
    type: SYNC_ALLOCATIONS,
    taskid,
    userGroupsSelected,
  });
};

export const updateTaskStatus = (status) => (dispatch) => {
  dispatch({
    type: UPDATE_TASK_STATUS,
    status,
  });
};
export const updatePermission = (permission) => (dispatch) => {
  dispatch({
    type: UPDATE_PERMISSION,
    permission,
  });
};
export const updateTaskQuestions = (taskQuestions) => (dispatch) => {
  dispatch({
    type: UPDATE_TASK_QUESTIONS,
    taskQuestions,
  });
};

export const updateLoader = (toggle) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADER,
    toggle,
  });
};

export const updateManagerPermission = (managerPermissions) => (dispatch) => {
  dispatch({
    type: UPDATE_MANAGER_PERMISSION,
    managerPermissions,
  });
};

export const updateManagerPermissionIndex = (index, managerDetails) => (dispatch) => {
  dispatch({
    type: UPDATE_MANAGER_PERMISSION_INDEX,
    index,
    managerDetails,
  });
};

export const deleteSubForm = (questionIndex, parentIndex) => (dispatch) => {
  dispatch({
    type: DELETE_SUB_FORM,
    questionIndex,
    parentIndex,
  });
};

export const updateSubForm = (question, questionIndex, parentIndex) => (dispatch) => {
  dispatch({
    type: UPDATE_SUB_FORM,
    question,
    questionIndex,
    parentIndex,
  });
};

export const updateSubFormOptionValue = (index, questionIndex, subformIndex, value) => (dispatch) => {
  dispatch({
    type: UPDATE_SUBFORM_OPTION,
    index,
    questionIndex,
    subformIndex,
    value,
  });
};

export const deleteSubFormOption = (index, questionIndex, subformIndex) => (dispatch) => {
  dispatch({
    type: DELETE_SUBFORM_OPTION,
    index,
    questionIndex,
    subformIndex,
  });
};
