import {
  HANDLE_DATASET,
  HANDLE_DATASET_PAGINATION,
  SET_DATASET_METADATA,
  TOGGLE_DATASET_CURRENTPAGE,
  UPDATE_DATASET,
} from '../actions/types';

const initialState = {
  datasetName: '',
  totalPages: 0,
  totalCount: 0,
  currentPage: 1,
  dataset: [],
  columnDataset: [],
  headersType: [],
  headers: [],
  changeListener: 1,
  color: 'red',
  icon: false,
  canEdit: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DATASET_METADATA:
      return {
        ...state,
        currentPage: 1,
        changeListener: action.changeListener,
        datasetName: action.datasetName,
        totalPages: action.totalPages,
        totalCount: action.totalCount,
        headers: action.headers,
        headersType: action.headersType,
        color: action.color,
        icon: action.icon,
        canEdit: action.canEdit,
      };
    case HANDLE_DATASET_PAGINATION:
      return {...state, currentPage: action.currentPage};
    case HANDLE_DATASET:
      return {...state, dataset: action.dataset, columnDataset: action.columnDataset};
    case TOGGLE_DATASET_CURRENTPAGE:
      return {...state, currentPage: action.currentPage};
    case UPDATE_DATASET:
      return {...state, dataset: action.dataset};
    default:
      return state;
  }
}
