import DownIcon from 'mdi-react/ChevronDownIcon';
import React, {PureComponent} from 'react';
import {Collapse} from 'reactstrap';

import TopbarMenuLink from './TopbarMenuLink';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
// Formatting
String.prototype.capitalize = function () {
  var result = this.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export default class TopBarName extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({collapse: !prevState.collapse}));
  };

  render() {
    const {collapse} = this.state;

    return (
      <div className="topbar__profile" style={{margin: 0}}>
        <button type="button" className="topbar__avatar" onClick={this.toggle} style={{padding: 0}}>
          <h5
            className={'fp-text-sub-heading-1'}
            style={{
              color: '#333333',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}>
            {this.props.name}
          </h5>
          <DownIcon className="topbar__icon" style={{fill: '#333333'}} />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu height-500 scrollFP">
            {this.props.users.map((item) => (
              <TopbarMenuLink title={item.userid} user={item} companyId={this.props.companyId} icon="list" path={item.userid} />
            ))}
          </div>
        </Collapse>
      </div>
    );
  }
}
