class Authenticate {
  login(data) {
    localStorage.setItem('user', JSON.stringify(data));
  }

  logout() {
    localStorage.removeItem('user');
  }

  isAuthenticated() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token != null) {
      return true;
    }
    return false;
  }

  getName() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.user.companyName;
  }

  getToken = () => localStorage.getItem('fp-token');
  // getToken() {
  //     //const user = JSON.parse(localStorage.getItem("user"));
  //     return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im5ld21hbmFnZXIzQGdtYWlsLmNvbSIsImNvbXBhbnlJZCI6IkxOSWhIQkRZb00iLCJyb2xlIjoiQWRtaW4iLCJpYXQiOjE1ODc5NjI3MTMsImV4cCI6MTU4ODM5NDcxM30.v1MMKObJigneZkS-xQX0UD1cOj7RiCRwUoCtp9mt5wM";
  //     // return user.token;
  // }

  getCompanyDetails() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user ? user.user : undefined;
  }

  getLoggedUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.user.email;
  }

  // doFetch = (type, data, url) => {
  //   console.log("called")
  //   if (data === null || data === undefined) { data = {}; }
  //   return new Promise(function(resolve,reject){
  //     const getData = (type, data, url) => {
  //   if (type === "GET") {
  //     const url1 = new URL(url),
  //       params = { token: this.getToken(), ...data };
  //     Object.keys(params).forEach(key =>
  //       url1.searchParams.append(key, params[key])
  //     );

  //     return fetch(url1);
  //   }
  //   return fetch(url, {
  //     method: type,
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({ token: this.getToken(), ...data })
  //   });
  //     }
  //     getData.onload = () => {
  //       console.log(getData)
  //       resolve(getData);
  //     }

  //     getData.onerror = () => {
  //       console.log(getData);
  //       reject('error')
  //     }
  //   })

  // };

  doFetch = (type, data, url) => {
    if (data === null || data === undefined) {
      data = {};
    }

    if (type === 'GET') {
      const url1 = new URL(url),
        params = {token: this.getToken(), ...data};
      Object.keys(params).forEach((key) => url1.searchParams.append(key, params[key]));

      return fetch(url1);
    }
    return fetch(url, {
      method: type,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({token: this.getToken(), ...data}),
    });
  };
}

// eslint-disable-next-line import/no-default-export
export default new Authenticate();
