import {SET_CURRENT_LOCATIONS, SET_SELECTED_USER, SET_USER_TIMELINE, SET_USER_MODE, SET_LAST_SYNCED} from './types';

export const setCurrentLocations = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_LOCATIONS,
    data,
  });
};

export const selectUser = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_USER,
    data,
  });
};

export const setUserTimeline = (data) => (dispatch) => {
  dispatch({
    type: SET_USER_TIMELINE,
    data,
  });
};

export const setUserMode = (data) => (dispatch) => {
  dispatch({
    type: SET_USER_MODE,
    data,
  });
};

export const setLastSync = (data) => (dispatch) => {
  dispatch({
    type: SET_LAST_SYNCED,
    data,
  });
};
