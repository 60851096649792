import '../../scss/app.scss';
import 'bootstrap/dist/css/bootstrap.css';

import {SnackbarProvider} from 'notistack';
import React, {Component, Fragment} from 'react';
import {hot} from 'react-hot-loader';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {initGA} from '../../services';
import Notifier from '../../shared/fpComponents/notifier';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import store from './store';

// eslint-disable-next-line import/no-extraneous-dependencies

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    if (window.location.origin === 'https://client.fieldproxy.com') {
      initGA();
    }
    window.addEventListener('load', () => {
      this.setState({loading: false});
      setTimeout(() => this.setState({loaded: true}), 500);
    });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      global.isMobile = true;
    }
  }

  render() {
    const {loaded, loading} = this.state;
    return (
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <ReactTooltip />
          <BrowserRouter>
            <ScrollToTop>
              <Fragment>
                {!loaded && (
                  <div className={`load${loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                      </svg>
                    </div>
                  </div>
                )}

                <div>
                  <Notifier />
                  <Router />
                </div>
              </Fragment>
            </ScrollToTop>
          </BrowserRouter>
        </SnackbarProvider>
      </Provider>
    );
  }
}

export default hot(module)(App);
