import React from 'react';

import {HANDLE_DATASET, HANDLE_DATASET_PAGINATION, SET_DATASET_METADATA, TOGGLE_DATASET_CURRENTPAGE, UPDATE_DATASET} from './types';

export const setDatasetMetadata = (metaData) => (dispatch) => {
  dispatch({
    type: SET_DATASET_METADATA,
    datasetName: metaData.datasetName,
    totalCount: metaData.totalPages,
    totalPages: Math.ceil(metaData.totalPages / 20),
    headers: metaData.headers,
    headersType: metaData.headersType,
    color: metaData.color,
    icon: metaData.icon,
    canEdit: metaData.canEdit,
    changeListener: metaData.changeListener,
  });
};

export const handlePagination = (page) => (dispatch) => {
  dispatch({
    type: HANDLE_DATASET_PAGINATION,
    currentPage: page,
  });
};

export const toggleCurrentPage = (currentPage) => (dispatch) => {
  dispatch({
    type: TOGGLE_DATASET_CURRENTPAGE,
    currentPage: currentPage,
  });
};

export const handleDataSet = (data) => (dispatch) => {
  dispatch({
    type: HANDLE_DATASET,
    dataset: data,
  });
};

export const updateDataSet = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_DATASET,
    dataset: data,
  });
};
