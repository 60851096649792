import moment from 'moment-timezone';
import React, {useState} from 'react';
import Select from 'react-select';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';

import API from '../../../shared/api';
import apiUrl from '../../../shared/apiURL';
import RenderDropZoneField from './dropzpne';

// import {  reduxForm } from 'redux-form';

// import { withTranslation } from 'react-i18next';
const FileUploadDefault = ({toggle, userType, pullProfile}) => {
  const [logoFile, setLogoFile] = useState([]);
  const [photoFile, setPhotoFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [timezone, setTimezone] = useState(moment.tz.guess());

  const submit = async () => {
    setUploading(true);
    const profileData = await API.getProfile();

    if (logoFile.length > 0 || photoFile.length > 0) {
      if (logoFile.length > 0) {
        const formData = new FormData();
        formData.append('file', logoFile[0]);
        const response = await API.doUpload(formData);
        if (response) {
          profileData.logo = response.imageUrl;
        }
      }
      if (photoFile.length > 0) {
        const formData = new FormData();
        formData.append('file', photoFile[0]);
        const response = await API.doUpload(formData);
        if (response) {
          profileData.avatar = response.imageUrl;
        }
      }
      profileData.timezone = timezone;
      const {avatar, logo} = profileData;
      const response = await API.doFetch('POST', {timezone, avatar, logo}, apiUrl.updateProfile);
      if (response) {
        pullProfile();
      }
    } else {
      alert('please select a file');
    }
    setUploading(false);
  };

  return (
    <>
      <div style={{textAlign: 'center'}}>
        <h4 className="bold-text">Update Profile Preferences </h4>
      </div>
      <Row>
        {userType === 'Admin' ? (
          <>
            <Col md={6}>
              <Card className="card--not-full-height">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text" style={{textAlign: 'center'}}>
                      Logo
                    </h5>
                  </div>
                  <RenderDropZoneField onChange={setLogoFile} file={logoFile} />
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="card--not-full-height">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text" style={{textAlign: 'center'}}>
                      Manager Photo
                    </h5>
                  </div>
                  <RenderDropZoneField onChange={setPhotoFile} file={photoFile} />
                </CardBody>
              </Card>
            </Col>
          </>
        ) : (
          <Col md={12}>
            <Card className="card--not-full-height">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text" style={{textAlign: 'center'}}>
                    Manager Photo
                  </h5>
                </div>
                <RenderDropZoneField onChange={setPhotoFile} file={photoFile} />
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      <div class="list-group-item">
        <span> Select Time Zone</span>
        <div style={{width: '250px'}}>
          <Select
            name="timezone"
            className="react-select"
            classNamePrefix="react-select"
            placeholder={'Select a timezone'}
            value={timezone ? {label: timezone, value: timezone} : ''}
            isSearchable={true}
            onChange={(e) => setTimezone(e.value)}
            options={moment.tz.names().map((d) => {
              return {label: d, value: d};
            })}
          />
        </div>
      </div>
      <div style={{justifyContent: 'center'}} className="mt-3 account__btns register__btns">
        {uploading ? (
          <Button
            onClick={submit}
            style={{width: '250px', opacity: '0.7', pointerEvents: 'none'}}
            type="submit"
            color="primary"
            className="account__btn">
            Uploading...
          </Button>
        ) : (
          <Button onClick={submit} style={{width: '250px'}} type="submit" color="primary" className="account__btn">
            Submit
          </Button>
        )}
      </div>
      <div className="account__have-account">
        <p onClick={toggle} style={{fontSize: '15px', cursor: 'pointer'}}>
          skip
        </p>
      </div>
    </>
  );
};

export default FileUploadDefault;
