import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import LibraryAddCheckIcon from 'mdi-react/CheckboxMultipleMarkedIcon';
import FileChartIcon from 'mdi-react/FileChartIcon';
import FileTableOutlineIcon from 'mdi-react/FileTableOutlineIcon';
import MarkerIcon from 'mdi-react/MapMarkerIcon';
import ViewDashboardIcon from 'mdi-react/ViewDashboardIcon';
import WorkflowIcon from 'mdi-react/WorkflowIcon';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

const BASE_ICON_SIZE = 20;
const SELECTED_ICON_COLOR = '#FFFFFF';
const BASE_ICON_COLOR = '#4CAF50';
const ACTIVE_BACKGROUND_COLOR = '#4CAF50';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const {onClick} = this.props;
    onClick();
  };

  isWorkFlowPage = () => {
    let href = this.props.pathname;
    var pattern = 'home/workflow';
    return href.includes(pattern);
  };

  isDashboardPage = () => {
    let href = this.props.pathname;
    var pattern = 'home/dashboard';
    return href.includes(pattern);
  };

  isTeamPage = () => {
    let href = this.props.pathname;
    var pattern = 'home/team';
    return href.includes(pattern);
  };

  isDatasetPage = () => {
    let href = this.props.pathname;
    var pattern = 'home/dataset';
    return href.includes(pattern);
  };

  isResponsePage = () => {
    let href = this.props.pathname;
    var pattern = 'home/responses';
    return href.includes(pattern);
  };

  isForumPage = () => {
    let href = this.props.pathname;
    var pattern = 'home/forums';
    return href.includes(pattern);
  };

  isReportPage = () => {
    let href = this.props.pathname;
    var pattern = 'home/reports';
    return href.includes(pattern);
  };

  isProfilePage = () => {
    let href = this.props.pathname;
    var pattern = 'home/profile';
    return href.includes(pattern);
  };

  onHover = (id) => {
    document.getElementById(id).style.width = '23px';
    document.getElementById(id).style.height = '23px';
    document.getElementById(id).style.marginTop = '-0.3rem';
    document.getElementById(id).style.marginLeft = '-0.1rem';
  };

  onLeave = (id, flag) => {
    if (!flag) {
      document.getElementById(id).style.width = '20px';
      document.getElementById(id).style.height = '20px';
      document.getElementById(id).style.marginTop = '-0.2rem';
      document.getElementById(id).style.marginLeft = '0rem';
    }
  };

  renderStyle = (func) => {
    return {
      width: func() ? '23px' : '20px',
      height: func() ? '23px' : '20px',
      marginTop: func() ? '-0.3rem' : '-0.2rem',
      marginLeft: func() ? '-0.1rem' : 0,
    };
  };

  render() {
    const {changeToDark, changeToLight} = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <NavLink
            to={`/${this.props.companyId}/home/workflows`}
            onClick={this.hideSidebar}
            isActive={() => {
              return this.isWorkFlowPage();
            }}
            activeStyle={{
              backgroundColor: ACTIVE_BACKGROUND_COLOR,
            }}
            activeClassName="sidebar__link-active">
            <li
              onMouseOver={() => this.onHover('workflow')}
              onMouseLeave={() => this.onLeave('workflow', this.isWorkFlowPage())}
              className="sidebar__link mt-2">
              <WorkflowIcon
                id="workflow"
                size={BASE_ICON_SIZE}
                style={this.renderStyle(this.isWorkFlowPage)}
                color={this.isWorkFlowPage() ? SELECTED_ICON_COLOR : BASE_ICON_COLOR}
              />
              <p
                className="sidebar__link-title"
                style={{marginTop: '0 rem', marginLeft: '0.3rem', color: this.isWorkFlowPage() ? SELECTED_ICON_COLOR : '#172B4D'}}>
                Workflows
              </p>
            </li>
          </NavLink>
          <br />
          <NavLink
            to={`/${this.props.companyId}/home/dashboard`}
            activeStyle={{
              backgroundColor: ACTIVE_BACKGROUND_COLOR,
            }}
            onClick={this.hideSidebar}
            activeClassName="sidebar__link-active">
            <li
              onMouseOver={() => this.onHover('dashboard')}
              onMouseLeave={() => this.onLeave('dashboard', this.isDashboardPage())}
              className="sidebar__link">
              <ViewDashboardIcon
                id="dashboard"
                size={BASE_ICON_SIZE}
                style={this.renderStyle(this.isDashboardPage)}
                color={this.isDashboardPage() ? SELECTED_ICON_COLOR : BASE_ICON_COLOR}
              />
              <p
                className="sidebar__link-title"
                style={{marginTop: '0 rem', marginLeft: '0.3rem', color: this.isDashboardPage() ? SELECTED_ICON_COLOR : '#172B4D'}}>
                Dashboard
              </p>
            </li>
          </NavLink>
          <br />

          <NavLink
            to={`/${this.props.companyId}/home/responses`}
            activeStyle={{
              backgroundColor: ACTIVE_BACKGROUND_COLOR,
            }}
            onClick={this.hideSidebar}
            activeClassName="sidebar__link-active">
            <li
              onMouseOver={() => this.onHover('response')}
              onMouseLeave={() => this.onLeave('response', this.isResponsePage())}
              className="sidebar__link mt-2">
              <LibraryAddCheckIcon
                id="response"
                size={BASE_ICON_SIZE}
                style={this.renderStyle(this.isResponsePage)}
                color={this.isResponsePage() ? SELECTED_ICON_COLOR : BASE_ICON_COLOR}
              />
              <p
                className="sidebar__link-title"
                style={{marginTop: '0 rem', marginLeft: '0.3rem', color: this.isResponsePage() ? SELECTED_ICON_COLOR : '#172B4D'}}>
                Responses
              </p>
            </li>
          </NavLink>
          <br />
          <NavLink
            to={`/${this.props.companyId}/home/dataset`}
            onClick={this.hideSidebar}
            activeStyle={{
              backgroundColor: ACTIVE_BACKGROUND_COLOR,
            }}
            activeClassName="sidebar__link-active">
            <li
              onMouseOver={() => this.onHover('dataset')}
              onMouseLeave={() => this.onLeave('dataset', this.isDatasetPage())}
              className="sidebar__link">
              <FileTableOutlineIcon
                id="dataset"
                size={BASE_ICON_SIZE}
                style={this.renderStyle(this.isDatasetPage)}
                color={this.isDatasetPage() ? SELECTED_ICON_COLOR : BASE_ICON_COLOR}
              />
              <p
                className="sidebar__link-title"
                style={{marginTop: '0 rem', marginLeft: '0.3rem', color: this.isDatasetPage() ? SELECTED_ICON_COLOR : '#172B4D'}}>
                Datasets
              </p>
            </li>
          </NavLink>
          <br />
          <NavLink
            to={`/${this.props.companyId}/home/team`}
            activeStyle={{
              backgroundColor: ACTIVE_BACKGROUND_COLOR,
            }}
            onClick={this.hideSidebar}
            activeClassName="sidebar__link-active">
            <li
              onMouseOver={() => this.onHover('team')}
              onMouseLeave={() => this.onLeave('team', this.isTeamPage())}
              className="sidebar__link mt-2">
              <AccountGroupIcon
                id="team"
                size={BASE_ICON_SIZE}
                style={this.renderStyle(this.isTeamPage)}
                color={this.isTeamPage() ? SELECTED_ICON_COLOR : BASE_ICON_COLOR}
              />
              <p
                className="sidebar__link-title"
                style={{marginTop: '0 rem', marginLeft: '0.3rem', color: this.isTeamPage() ? SELECTED_ICON_COLOR : '#172B4D'}}>
                Teams
              </p>
            </li>
          </NavLink>
          <br />
          <NavLink
            to={`/${this.props.companyId}/home/reports`}
            activeStyle={{
              backgroundColor: ACTIVE_BACKGROUND_COLOR,
            }}
            onClick={this.hideSidebar}
            activeClassName="sidebar__link-active">
            <li
              onMouseOver={() => this.onHover('reports')}
              onMouseLeave={() => this.onLeave('reports', this.isReportPage())}
              className="sidebar__link">
              <FileChartIcon
                id="reports"
                size={BASE_ICON_SIZE}
                style={this.renderStyle(this.isReportPage)}
                color={this.isReportPage() ? SELECTED_ICON_COLOR : BASE_ICON_COLOR}
              />
              <p
                className="sidebar__link-title"
                style={{marginTop: '0 rem', marginLeft: '0.3rem', color: this.isReportPage() ? SELECTED_ICON_COLOR : '#172B4D'}}>
                Reports
              </p>
            </li>
          </NavLink>
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
