import Loadable from '@loadable/component';
import React from 'react';
import {BrowserView, MobileView} from 'react-device-detect';
import {Route, Switch, withRouter} from 'react-router-dom';

import {PageView} from '../../services';
import Layout from '../Layout';
import MainWrapper from './MainWrapper';

const Loading = () => (
  <div style={{position: 'absolute', top: 0}}>
    <div className="load__icon-wrap">
      <svg className="load__icon">
        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </svg>
    </div>
  </div>
);

const LogIn = Loadable(() => import('../LogIn/index'), {
  fallback: <Loading />,
});

const SignUp = Loadable(() => import('../SignUp/index'), {
  fallback: <Loading />,
});

const SignUpProfile = Loadable(() => import('../Profile/index'), {
  fallback: <Loading />,
});

const Createtab = Loadable(() => import('../create/index'), {
  fallback: <Loading />,
});

const Tasks = Loadable(() => import('../Tasks/index'), {
  fallback: <Loading />,
});

const Pdf = Loadable(() => import('../pdf/index'), {
  fallback: <Loading />,
});

const Dataset = Loadable(() => import('../dataset/index'), {
  fallback: <Loading />,
});

const DataSetDetails = Loadable(() => import('../dataSetDetails/index'), {
  fallback: <Loading />,
});

const Workflow = Loadable(() => import('../workflow/workflow'), {
  fallback: <Loading />,
});

const Workflows = Loadable(() => import('../workflow/index'), {
  fallback: <Loading />,
});

const Dashboard = Loadable(() => import('../dashboard/index'), {
  fallback: <Loading />,
});

const Reports = Loadable(() => import('../reports'), {
  fallback: <Loading />,
});

const ReportDetail = Loadable(() => import('../reports/reportDetails'), {
  fallback: <Loading />,
});

const CreateWorkflow = Loadable(() => import('../createWorkflow'), {
  fallback: <Loading />,
});

const CreateTask = Loadable(() => import('../createTask'), {
  fallback: <Loading />,
});

const CreatePDF = Loadable(() => import('../createPdf'), {
  fallback: <Loading />,
});

const DatasetToTask = Loadable(() => import('../datasetToTask'), {
  fallback: <Loading />,
});

const NewWorkflow = Loadable(() => import('../createWorkflow'), {
  fallback: <Loading />,
});

const Responses = Loadable(() => import('../responses'), {
  fallback: <Loading />,
});

const TaskResponses = Loadable(() => import('../responses/taskView'), {
  fallback: <Loading />,
});

const WorkFlowResponses = Loadable(() => import('../responses/workflowView'), {
  fallback: <Loading />,
});

const Team = Loadable(() => import('../Team'), {
  fallback: <Loading />,
});

const permissions = Loadable(() => import('../permissions'), {
  fallback: <Loading />,
});

const Profile = Loadable(() => import('../ProfileDashboard'), {
  fallback: <Loading />,
});

const LocationTracking = Loadable(() => import('../locationTracking'), {
  fallback: <Loading />,
});

const LocationTimeline = Loadable(() => import('../locationTracking/timelineView'), {
  fallback: <Loading />,
});

const LiveSharing = Loadable(() => import('../livesharing'), {
  fallback: <Loading />,
});

const BicTracker = Loadable(() => import('../bicTracker'), {
  fallback: <Loading />,
});

const Expired = Loadable(() => import('../expired'), {
  fallback: <Loading />,
});

const Forms = Loadable(() => import('../forms'), {
  fallback: <Loading />,
});

const ComponentsPage = Loadable(() => import('./Components'), {
  fallback: <Loading />,
});

const Pages = () => (
  <Switch>
    <Route exact path="/:companyId/home/tasks" component={Tasks} />
    <Route exact path="/:companyId/home/tasks/:taskid" component={Createtab} />
    <Route exact path="/:companyId/home/dataset" component={Dataset} />
    <Route exact path="/:companyId/home/workflow/:workflowId/tasks/new" component={CreateTask} />
    <Route exact path="/:companyId/home/workflow/:workflowId/pdf/new" component={CreatePDF} />
    <Route exact path="/:companyId/home/workflow/:workflowId/pdf/:pdfId" component={Pdf} />
    <Route exact path="/:companyId/home/workflow/:workflowId/tasks/:taskid" component={Createtab} />
    <Route exact path="/:companyId/home/workflow/new" component={NewWorkflow} />
    <Route exact path="/:companyId/home/responses" component={Responses} />
    <Route exact path="/:companyId/home/responses/task-view/:workflowID" component={TaskResponses} />
    <Route exact path="/:companyId/home/responses/workflow-view/:workflowID" component={WorkFlowResponses} />
    <Route exact path="/:companyId/home/team" component={Team} />

    <Route exact path="/:companyId/home/manager/permissions/:id" component={permissions} />
    <Route exact path="/:companyId/home/workflow/:parentWorkflowId/subworkflow/:workflowId" component={Workflow} />
    <Route exact path="/:companyId/home/workflow/:workflowId" component={Workflow} />

    <Route exact path="/:companyId/home/dataset/:dataSetID" component={DataSetDetails} />
    <Route exact path="/:companyId/home/workflows" component={Workflows} />
    <Route exact path="/:companyId/home/dashboard" component={Dashboard} />
    <Route exact path="/:companyId/home/reports/:reportId" component={ReportDetail} />
    <Route exact path="/:companyId/home/reports" component={Reports} />

    <Route exact path="/:companyId/home/create-workflow/:workflowId" component={CreateWorkflow} />
    <Route exact path="/:companyId/home/create-workflow" component={CreateWorkflow} />
    <Route exact path="/:companyId/home/:workflowID/create-task" component={CreateTask} />
    <Route exact path="/:companyId/home/task-dataset/:datasetID" component={DatasetToTask} />
    <Route exact path="/:companyId/home/profile" component={Profile} />
    <Route exact path="/:companyId/home/location-tracking" component={LocationTracking} />
    <Route exact path="/:companyId/home/location-tracking/:userId" component={LocationTimeline} />
    <Route exact path="/:companyId/home/bic-tracker" component={BicTracker} />
    {/* <Route exact path="/" component={Workflows} /> */}
  </Switch>
);

const MobilePages = () => (
  <Switch>
    <Route exact path="/:companyId/home/reports/:reportId" component={ReportDetail} />
    <Route exact path="/:companyId/home/reports" component={Reports} />
    <Route exact path="/:companyId/home/location-tracking" component={LocationTracking} />
    <Route exact path="/:companyId/home/location-tracking/:userId" component={LocationTimeline} />
    <Route exact path="/:companyId/home/dashboard" component={Dashboard} />

    <Route path="/" component={Reports} />
  </Switch>
);

const boolCheck = true;

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      {boolCheck ? <Route path="/:companyId/home" component={Pages} /> : <Route path="/:companyId/home" component={Expired} />}
      {/* <Route path="/" component={Pages} /> */}
    </div>
  </div>
);

const wrappedRoutesMobile = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      {boolCheck ? <Route path="/:companyId/home" component={MobilePages} /> : <Route path="/:companyId/home" component={Expired} />}
      {/* <Route path="/" component={Pages} /> */}
    </div>
  </div>
);

const wrappedRoutes1 = (props) => {
  props.history.push('/login');
  return null;
};

class RouterComponent extends React.Component {
  componentDidMount = () => {
    if (window.location.origin === 'https://client.fieldproxy.com') {
      PageView(window.location.pathname + window.location.search);
      this.props.history.listen((location) => {
        PageView(location.pathname);
      });
    }
  };

  render() {
    return (
      <>
        <BrowserView>
          <MainWrapper>
            <main>
              <Switch>
                <Route exact path="/fp-components" component={ComponentsPage} />
                <Route exact path="/login" component={LogIn} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/signup/:token" component={SignUp} />
                <Route exact path="/signup-profile" component={SignUpProfile} />
                <Route exact path="/:companyId/login/:token" component={LogIn} />
                <Route path="/fplive/:companyId/:liveId/:type" component={LiveSharing} />
                <Route path="/:companyId/home" component={wrappedRoutes} />
                <Route exact path="/forms/:formId" component={Forms} />
                {/* <Route exact path="/:companyId/forms/:workflowId/:taskId/:userToken/:isFirstTask/:primaryResponse" component={Forms} /> */}
                <Route exact path="/:comapanyId/disabled" component={Expired} />
                <Route path="/" component={wrappedRoutes1} />
              </Switch>
            </main>
          </MainWrapper>
        </BrowserView>
        <MobileView>
          <MainWrapper>
            <main>
              <Switch>
                <Route exact path="/login" component={LogIn} />

                <Route path="/fplive/:companyId/:liveId/:type" component={LiveSharing} />
                <Route exact path="/:companyId/login/:token" component={LogIn} />
                <Route path="/:companyId/home" component={wrappedRoutesMobile} />
                <Route exact path="/forms/:formId" component={Forms} />
                {/* <Route exact path="/:companyId/forms/:workflowId/:taskId/:userToken/:isFirstTask/:primaryResponse" component={Forms} /> */}
                <Route exact path="/:comapanyId/disabled" component={Expired} />
                <Route path="/" component={wrappedRoutes1} />
              </Switch>
            </main>
          </MainWrapper>
        </MobileView>
      </>
    );
  }
}

export default withRouter(RouterComponent);

// const LogIn = React.lazy(() => import('../LogIn/index'))
// const SignUp = React.lazy(() => import('../SignUp/index'))
// const SignUpProfile = React.lazy(() => import('../Profile/index'))
// const Createtab = React.lazy(() => import('../create/index'))
// const Tasks = React.lazy(() => import('../Tasks/index'))
// const Pdf = React.lazy(() => import('../pdf/index'))
// const Dataset = React.lazy(() => import("../dataset/index"))
// const DataSetDetails = React.lazy(() => import("../dataSetDetails/index"))
// const Workflow = React.lazy(() => import('../workflow/workflow'))
// const Workflows = React.lazy(() => import('../workflow/index'))
// const Dashboard = React.lazy(() => import('../dashboard/index'))
// const Reports = React.lazy(() => import('../reports/index'))
// const CreateWorkflow = React.lazy(() => import('../createWorkflow'))
// const CreateTask = React.lazy(() => import('../createTask'))
// const CreatePDF = React.lazy(() => import('../createPdf'))
// const DatasetToTask = React.lazy(() => import('../datasetToTask'))
// const NewWorkflow = React.lazy(() => import('../createWorkflow'))
// const Responses = React.lazy(() => import('../responses'))
// const TaskResponses = React.lazy(() => import('../responses/taskView'))
// const WorkFlowResponses = React.lazy(() => import('../responses/workflowView'))
// const Team = React.lazy(() => import('../Team'))
// const permissions = React.lazy(() => import('../permissions'))
// const Profile = React.lazy(() => import('../ProfileDashboard'))
// const LocationTracking = React.lazy(() => import('../locationTracking'))
// const LocationTimeline = React.lazy(() => import('../locationTracking/timelineView'))
// const LiveSharing = React.lazy(() => import('../livesharing'))
// const BicTracker = React.lazy(() => import('../bicTracker'))
// const Expired = React.lazy(() => import('../expired'))
// const Forms = React.lazy(() => import('../forms'))
