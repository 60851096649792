import MenuIcon from 'mdi-react/MenuIcon';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;

class TopbarSidebarButton extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const {changeMobileSidebarVisibility, changeSidebarVisibility} = this.props;

    return (
      <div>
        <button type="button" className="topbar__button topbar__button--desktop" onClick={changeSidebarVisibility}>
          <MenuIcon style={{color: '#bcc0c5', alignSelf: 'center'}} />
          {/* <img src={icon} alt="" className="topbar__button-icon" /> */}
        </button>
        <button type="button" className="topbar__button topbar__button--mobile" onClick={changeMobileSidebarVisibility}>
          <MenuIcon style={{color: '#bcc0c5', alignSelf: 'center'}} />
          {/* <img src={icon} alt="" className="topbar__button-icon" /> */}
        </button>
      </div>
    );
  }
}

export default TopbarSidebarButton;
