import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';

import logo from '../../../assets/images/icon.png';
import {SidebarProps} from '../../../shared/prop-types/ReducerProps';
import SidebarContent from './SidebarContent';

const Sidebar = ({
  changeToDark,
  changeToLight,
  changeMobileSidebarVisibility,
  sidebar,
  workflowPage,
  pathname,
  datasetToTask,
  companyId,
}) => {
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
  });

  return (
    <div className={sidebarClass} style={{paddingTop: workflowPage ? 0 : datasetToTask ? 0 : '60px'}}>
      {(!!workflowPage || !!datasetToTask) && <img src={logo} style={{height: '40px', width: '40px', marginTop: '8px', marginLeft: 8}} />}
      <button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility} />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent
            pathname={pathname}
            companyId={companyId}
            onClick={() => {}}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            pathname={pathname}
            companyId={companyId}
            onClick={changeMobileSidebarVisibility}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
          />
        </div>
      </Scrollbar>
    </div>
  );
};

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
