import LocationOffIcon from '@material-ui/icons/LocationOff';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';

export default class TopbarMenuLinks extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };

  render() {
    const {title, icon, path, user, companyId} = this.props;

    return (
      <Link className="topbar__link" to={path ? path : `/${companyId}home/location-tracking`}>
        <div style={{width: '25px'}}>
          {user.lastKnownLocation ? (
            <div className={user.status === 'active' ? 'online' : 'offline'} />
          ) : (
            <LocationOffIcon className="offLocation" />
          )}
        </div>
        <p className="topbar__link-title">{title}</p>
      </Link>
    );
  }
}
