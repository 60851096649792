export const UPDATE_TASK_NAME = 'UPDATE_TASK_NAME';
export const UPDATE_INFO = 'UPDATE_INFO';
export const GET_INFO = 'GET_INFO';
export const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';
export const UPDATE_QUESTIONS_FROM_RESPONSE = 'UPDATE_QUESTIONS_FROM_RESPONSE';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const UPDATE_QUESTIONS_DETAILS = 'UPDATE_QUESTIONS_DETAILS';
export const COPY_QUESTION = 'COPY_QUESTION';
export const UPDATE_CURRENT_INDEX = 'UPDATE_CURRENT_INDEX';
export const DELETE_CURRENT_SUB_INDEX = 'DELETE_CURRENT_SUB_INDEX';
export const DELETE_SUB_GROUP = 'DELETE_SUB_GROUP';
export const UPDATE_SUB_GROUP_QUESTION = 'UPDATE_SUB_GROUP_QUESTION';
export const UPDATE_SUB_INDEX = 'UPDATE_SUB_INDEX';
export const UPDATE_SIGNUP_PARAMS = 'UPDATE_SIGNUP_PARAMS';
export const UPDATE_LOGIN_PARAMS = 'UPDATE_LOGIN_PARAMS';
export const UPDATE_TASK_ID = 'UPDATE_TASK_ID';
export const UPDATE_TASK_DESCRIPTION = 'UPDATE_TASK_DESCRIPTION';
export const UPDATE_DASHBOARD_MOUNTED = 'UPDATE_DASHBOARD_MOUNTED';
export const UPDATE_DASHQUESTIONS = 'UPDATE_DASHQUESTIONS';
export const UPDATE_RESPONSES = 'UPDATE_RESPONSES';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const UPDATE_EXCEL = 'UPDATE_EXCEL';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_NEW_LOCATION = 'UPDATE_NEW_LOCATION';
export const UPDATE_NEW_AGENT = 'UPDATE_NEW_AGENT';
export const UPDATE_NEW_EXCEL = 'UPDATE_NEW_EXCEL';
export const UPDATE_USER_PAGE = 'UPDATE_USER_PAGE';
export const UPDATE_TASK_SETTINGS = 'UPDATE_TASK_SETTINGS';
export const SYNC_ALLOCATIONS = 'SYNC_ALLOCATIONS';
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS';
export const RESET_NEW_LOCATION = 'RESET_NEW_LOCATION';
export const RESET_NEW_EXCEL = 'RESET_NEW_EXCEL';
export const RESET_NEW_AGENT = 'RESET_NEW_AGENT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const UPDATE_TASK_QUESTIONS = 'UPDATE_TASK_QUESTIONS';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const UPDATE_RESPONSE_FORMAT = 'UPDATE_RESPONSE_FORMAT';
export const UPDATE_FILTER_DATE_FROM = 'UPDATE_FILTER_DATE_FROM';
export const UPDATE_FILTER_DATE_TO = 'UPDATE_FILTER_DATE_TO';
export const UPDATE_FILTER_LOCATION = 'UPDATE_FILTER_LOCATION';
export const UPDATE_FILTER_USER = 'UPDATE_FILTER_USER';
export const UPDATE_FILTER_STATE = 'UPDATE_FILTER_STATE';
export const UPDATE_PAYMENT_DETAILS = 'UPDATE_PAYMENT_DETAILS';
export const UPDATE_HIDED_COMPONENTS = 'UPDATE_HIDED_COMPONENTS';
export const UPDATE_MANAGER_PERMISSION = 'UPDATE_MANAGER_PERMISSION';
export const UPDATE_TIME_LINE = 'UPDATE_TIME_LINE';
export const UPDATE_ISSUES = 'UPDATE_ISSUES';
export const GET_COMMENTS = 'GET_COMMENTS';
export const UPDATE_MANAGER = 'UPDATE_MANAGER';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const SELECT_ISSUE = 'SELECT_ISSUE';
export const UPDATE_REPLIES = 'UPDATE_REPLIES';
export const UPDATE_MANAGER_PERMISSION_INDEX = 'UPDATE_MANAGER_PERMISSION_INDEX';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const UPDATE_SUB_FORM = 'UPDATE_SUB_FORM';
export const DELETE_SUB_FORM = 'DELETE_SUB_FORM';
export const DELETE_SUBFORM_OPTION = 'DELETE_SUBFORM_OPTION';
export const UPDATE_SUBFORM_OPTION = 'UPDATE_SUBFORM_OPTION';
export const UPDATE_WORKFLOWS = 'UPDATE_WORKFLOWS';
export const SYNC_CHANGES = 'SYNC_CHANGES';
export const UPDATE_SYNC = 'UPDATE_SYNC';
export const UPDATE_SYNC_SETTINGS = 'UPDATE_SYNC_SETTINGS';
export const UPDATE_TASK_DATA = 'UPDATE_TASK_DATA';
export const UPDATE_META_DATA = 'UPDATE_META_DATA';
export const UPDATE_REPORT_DATA = 'UPDATE_REPORT_DATA';
export const UPDATE_TASK_ORDER = 'UPDATE_TASK_ORDER';
export const SAVE_WORKFLOW_STATUS = 'SAVE_WORKFLOW_STATUS';
export const UPDATE_USERLIST = 'UPDATE_USERLIST';
export const TOGGLE_DATASET_MODAL = 'TOGGLE_DATASET_MODAL';
export const SET_DATASET_METADATA = 'SET_DATASET_METADATA';
export const HANDLE_DATASET_PAGINATION = 'HANDLE_DATASET_PAGINATION';
export const HANDLE_DATASET = 'HANDLE_DATASET';
export const UPDATE_DATASET = 'UPDATE_DATASET';
export const TOGGLE_TASK_MODAL = 'TOGGLE_TASK_MODAL';
export const TOGGLE_WORKFLOW_MODAL = 'TOGGLE_WORKFLOW_MODAL';
export const TOGGLE_DATASET_CURRENTPAGE = 'TOGGLE_DATASET_CURRENTPAGE';
export const SET_GLOBAL_TASK = 'SET_GLOBAL_TASK';
export const SET_GLOBAL_WORKFLOW = 'SET_GLOBAL_WORKFLOW';

export const SET_TABS = 'SET_TABS';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const OPEN_TASK_SETTINGS = 'OPEN_TASK_SETTINGS';
export const SET_GLOBAL_DATASET = 'SET_GLOBAL_DATASET';
export const SET_GLOBAL_PDF = 'SET_GLOBAL_PDF';
export const SET_ONBOARD_STATUS = 'SET_ONBOARD_STATUS';
export const SET_WORKFLOW_NAME = 'SET_WORKFLOW_NAME';
export const SELECT_WORKFLOW = 'SELECT_WORKFLOW';
export const SET_TASK_NAME = 'SET_TASK_NAME';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PARENT_WORKFLOW = 'SET_PARENT_WORKFLOW';
export const SET_PARENT_PRIMARY_RESPONSE = 'SET_PARENT_PRIMARY_RESPONSE';
export const SET_CURRENT_LOCATIONS = 'SET_CURRENT_LOCATIONS';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SET_USER_TIMELINE = 'SET_USER_TIMELINE';
export const SET_USER_MODE = 'SET_USER_MODE';
export const SET_LAST_SYNCED = 'SET_LAST_SYNCED';

// team
export const SET_USER_MANAGEMENT_TYPE = 'SET_USER_MANAGEMENT_TYPE';
export const SET_TEAM_TYPE = 'SET_TEAM_TYPE';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_CREATE_USER_MODAL = 'SET_CREATE_USER_MODAL';
export const SET_TEAM_USER_DATA = 'SET_TEAM_USER_DATA';

// PDF
export const SELECT_PDF_ELEMENT = 'SELECT_PDF_ELEMENT';
export const SET_INVOICE_HEADER_DATA = 'SET_INVOICE_HEADER_DATA';
export const SET_INVOICE_BODY_DATA = 'SET_INVOICE_BODY_DATA';
export const SET_INVOICE_FOOTER_DATA = 'SET_INVOICE_FOOTER_DATA';
export const OPEN_PDF_SETTINGS = 'OPEN_PDF_SETTINGS';
export const UPDATE_PDF_NAME = 'UPDATE_PDF_NAME';
export const UPDATE_PDF_SETTINGS = 'UPDATE_PDF_SETTINGS';

export const SET_GLOBAL_ACCESS = 'SET_GLOBAL_ACCESS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

// notistack

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

// addOn

export const UPDATE_ADDONS = 'UPDATE_ADDONS';
export const UPDATE_ADDON = 'UPDATE_ADDON';
export const DELETE_ADDON = 'DELETE_ADDON';
export const UPDATE_CURRENT_ADDON_INDEX = 'UPDATE_CURRENT_ADDON_INDEX';
export const TOGGLE_SETTINGS_VIEW = 'TOGGLE_SETTINGS_VIEW';

// REPORT

export const TOGGLE_CREATE_REPORT_MODAL = 'TOGGLE_CREATE_REPORT_MODAL';
