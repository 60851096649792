import IconButton from '@material-ui/core/IconButton';
import DownIcon from 'mdi-react/ChevronDownIcon';
import moment from 'moment';
import React, {PureComponent} from 'react';
import {isMobile} from 'react-device-detect';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Popover, PopoverHeader} from 'reactstrap';

import Profile from '../../../assets/image/profile.jpg';
import NoNoti from '../../../assets/images/no-noti.png';
import NotiIcon from '../../../assets/images/noti-icon.png';
import {setNotifications} from '../../../redux/actions/global';
import Api from '../../../shared/api';
import APIURL from '../../../shared/apiURL';
import FPButton from '../../../shared/fpComponents/buttons';
import TopbarMenuLink from './TopbarMenuLink';

class TopBarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
      notiOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({collapse: !prevState.collapse}));
  };

  toggleNoti = () => {
    this.setState((prevState) => ({notiOpen: !prevState.notiOpen}));
  };

  getNotification = async () => {
    let notifications = await Api.doFetch('POST', {}, APIURL.getNotifications);
    if (notifications && notifications.success) {
      this.props.setNotifications(notifications.data);
    }
  };

  setAsRead = async (id) => {
    const {notifications} = this.props;
    const n = notifications.find((n) => n.notificationId === id);
    if (!n.read) {
      const resp = await Api.doFetch('POST', {notificationIds: [id]}, APIURL.readReceipts);
      if (resp && resp.success) {
        this.getNotification();
      }
    }
  };

  markAllRead = async () => {
    const notificationIds = this.props.notifications.map((n) => n.notificationId);
    const resp = await Api.doFetch('POST', {notificationIds}, APIURL.readReceipts);
    if (resp && resp.success) {
      this.getNotification();
    }
  };

  render() {
    const {collapse, notiOpen} = this.state;
    const {type, companyId, profile, notifications, unRead} = this.props;

    return (
      <div className="noti-wrapper">
        <div>
          <Dropdown isOpen={notiOpen} toggle={this.toggleNoti} direction="bottom" setActiveFromChild={false}>
            <DropdownToggle style={{margin: 0, padding: 0}} className={unRead ? 'active-indicator' : ''}>
              <IconButton>
                <img src={NotiIcon} alt="Notification" className="noti-icon" id="Popover1" />
              </IconButton>
            </DropdownToggle>
            <DropdownMenu className="noti-dd-menu" style={{paddingTop: 0}}>
              <PopoverHeader className="noti-dd-header">
                <div style={{fontWeight: 'bold'}}>Notifications</div>
                {unRead && notifications.length ? (
                  <div onClick={this.markAllRead} className="mark-as-read">
                    {' '}
                    Mark all as read{' '}
                  </div>
                ) : (
                  ''
                )}
              </PopoverHeader>
              {notifications.length ? (
                <div className="noti-dropdown">
                  {notifications.map((n) => (
                    <DropdownItem
                      toggle={false}
                      key={n.notificationId}
                      onClick={() => this.setAsRead(n.notificationId)}
                      style={{padding: 0}}>
                      <div className={`noti-menu ${n.read ? 'read' : ''} `}>
                        <div style={{flexBasis: 'auto', textAlign: 'left'}}>
                          <div className="fp-text-body"> {n.notificationTitle} </div>
                          {n.notes && n.notes.value ? <div className="fp-text-caption"> {n.notes.value} </div> : ''}
                          <div>
                            <span className="fp-text-caption"> {moment(n.lastModified).calendar()} </span>
                          </div>
                        </div>
                        {n.links && n.links.attachment ? (
                          <div>
                            <NavLink to={`/${companyId}/${n.links.attachment}`} className="no-hover">
                              <FPButton type={n.read ? 'mini-default' : 'mini-primary'} size="small">
                                {' '}
                                View{' '}
                              </FPButton>
                            </NavLink>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </DropdownItem>
                  ))}
                </div>
              ) : (
                <div className="noti-dropdown">
                  <DropdownItem disabled>
                    <div className="onboard-msg">
                      <img src={NoNoti} style={{width: '100px', height: '100px'}} />
                      <div> Hello {profile ? profile.firstName : 'There'} !</div>
                      <div> You don't have any notifications! </div>
                    </div>
                  </DropdownItem>
                </div>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="topbar__profile">
          <button type="button" className="topbar__avatar" id="toggler" onClick={this.toggle}>
            <img className="topbar__avatar-img" src={profile && profile.avatar ? profile.avatar : Profile} alt="avatar" />
            <p className="topbar__avatar-name" style={{fontSize: 16}}>
              {profile && profile.firstName} {profile && profile.lastName}
            </p>
            <DownIcon className="topbar__icon" />
          </button>
          <Popover
            toggler="#toggler"
            placement={isMobile ? 'left-end' : 'bottom-end'}
            target="toggler"
            isOpen={collapse}
            className="topbar__menu-wrap">
            <div className="topbar__menu">
              {type && type === 'profile' ? (
                ''
              ) : (
                <TopbarMenuLink
                  key="profile"
                  title="Profile"
                  icon="user"
                  path={type && type === 'managerPermission' ? `/${companyId}/home/profile` : 'profile'}
                />
              )}
              <TopbarMenuLink key="logout" title="Log Out" icon="exit" path="/login" />
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.global.notifications,
    unRead: state.global.unReadNotifications,
    profile: state.profile.profileData,
  };
};

const mapDispatchToProps = {
  setNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBarProfile);
