import {
  COPY_QUESTION,
  DELETE_ADDON,
  DELETE_CURRENT_SUB_INDEX,
  DELETE_QUESTION,
  DELETE_SUB_FORM,
  DELETE_SUB_GROUP,
  DELETE_SUBFORM_OPTION,
  OPEN_TASK_SETTINGS,
  SYNC_ALLOCATIONS,
  TOGGLE_SETTINGS_VIEW,
  UPDATE_ADDON,
  UPDATE_ADDONS,
  UPDATE_CURRENT_ADDON_INDEX,
  UPDATE_CURRENT_INDEX,
  UPDATE_LOADER,
  UPDATE_MANAGER_PERMISSION,
  UPDATE_MANAGER_PERMISSION_INDEX,
  UPDATE_META_DATA,
  UPDATE_PERMISSION,
  UPDATE_QUESTION,
  UPDATE_QUESTIONS,
  UPDATE_QUESTIONS_DETAILS,
  UPDATE_SUB_FORM,
  UPDATE_SUB_GROUP_QUESTION,
  UPDATE_SUB_INDEX,
  UPDATE_SUBFORM_OPTION,
  UPDATE_TASK_DATA,
  UPDATE_TASK_DESCRIPTION,
  UPDATE_TASK_ID,
  UPDATE_TASK_NAME,
  UPDATE_TASK_QUESTIONS,
  UPDATE_TASK_SETTINGS,
  UPDATE_TASK_STATUS,
} from '../actions/types';

const initialState = {
  questions: [],
  addOns: [],
  currentIndex: -1,
  currentAddOnIndex: -1,
  loader: false,
  editedFlag: false,
  editedQuestionsId: [],
  lastChanged: null,
  syncing: false,
  editedAddOnsId: [],
  taskName: '',
  taskId: null,
  taskDescription: '',
  permission: {},
  taskSettings: {},
  taskQuestions: [],
  questionsDetails: [],
  metaData: {},
  status: '',
  loader: false,
  settingsView: 'questions',
  managerPermissions: [],
  taskSettingsToggle: false,
};

const updateLoader = (state, action) => {
  return Object.assign({}, state, {
    loader: action.toggle,
  });
};

const updateQuestion = (state, action) => {
  const {question, index} = action;
  const {editedQuestionsId} = state;
  const questions = [...state.questions];
  questions[index] = question;
  if (question.type === 'dropdown') {
    questions[index].subGroup = [...question.subGroup];
  }

  if (!editedQuestionsId.includes(question.questionid)) {
    editedQuestionsId.push(question.questionid);
  }

  return Object.assign({}, state, {
    questions,
    editedFlag: true,
    editedQuestionsId,
    lastChanged: new Date().getTime(),
  });
};

const updateAddOn = (state, action) => {
  const {addOn, index} = action;
  const {editedAddOnsId} = state;
  const addOns = [...state.addOns];
  addOns[index] = addOn;

  if (!editedAddOnsId.includes(addOn.addOnId)) {
    editedAddOnsId.push(addOn.addOnId);
  }

  return Object.assign({}, state, {
    addOns,
    editedFlag: true,
    editedAddOnsId,
    lastChanged: new Date().getTime(),
  });
};

const updateSubGroupOption = (state, action) => {
  const {questionIndex, index, value} = action;
  const {editedQuestionsId} = state;
  const questions = [...state.questions];
  if (questions[questionIndex] !== undefined) {
    const currentQuestion = {...questions[questionIndex]};
    if (currentQuestion.subgroupQuestions[index] !== undefined) {
      const sub = [...currentQuestion.subgroupQuestions];
      sub[index] = value;
      currentQuestion.subgroupQuestions = sub;
    }
    questions[questionIndex] = Object.assign({}, {...currentQuestion});
    if (!editedQuestionsId.includes(currentQuestion.questionid)) {
      editedQuestionsId.push(currentQuestion.questionid);
    }
    return Object.assign({}, state, {
      questions,
      editedFlag: true,
      editedQuestionsId,
      lastChanged: new Date().getTime(),
    });
  }
  return state;
};

const deleteSubGroup = (state, action) => {
  const {questionIndex, index} = action;
  const {editedQuestionsId} = state;
  const questions = [...state.questions];
  if (questions[questionIndex] !== undefined) {
    const currentQuestion = {...questions[questionIndex]};
    if (currentQuestion.subGroup[index] !== undefined) {
      currentQuestion.subGroup.splice(action.index, 1);
      currentQuestion.subgroupQuestions.splice(action.index, 1);
    }
    questions[questionIndex] = Object.assign({}, {...currentQuestion});
    if (!editedQuestionsId.includes(currentQuestion.questionid)) {
      editedQuestionsId.push(currentQuestion.questionid);
    }
    return Object.assign({}, state, {
      questions,
      editedFlag: true,
      editedQuestionsId,
      lastChanged: new Date().getTime(),
    });
  }
  return state;
};

const deleteSubQuestion = (state, action) => {
  const {questionIndex, parentIndex} = action;
  const {editedQuestionsId} = state;
  const questions = [...state.questions];
  if (questions[questionIndex] !== undefined) {
    const currentQuestion = {...questions[questionIndex]};
    if (currentQuestion.subGroup[parentIndex] !== undefined) {
      const currentSubGroup = [...currentQuestion.subGroup[parentIndex]];
      currentSubGroup.splice(action.index, 1);
      currentQuestion.subGroup[parentIndex] = [...currentSubGroup];
    }
    questions[questionIndex] = Object.assign({}, {...currentQuestion});
    if (!editedQuestionsId.includes(currentQuestion.questionid)) {
      editedQuestionsId.push(currentQuestion.questionid);
    }
    return Object.assign({}, state, {
      questions,
      editedFlag: true,
      editedQuestionsId,
      lastChanged: new Date().getTime(),
    });
  }
  return state;
};

const updateSubQuestion = (state, action) => {
  const {question, questionIndex, parentIndex, index} = action;
  const {editedQuestionsId} = state;
  const questions = [...state.questions];

  if (questions[questionIndex] !== undefined) {
    const currentQuestion = {...questions[questionIndex]};
    if (currentQuestion.subGroup[parentIndex] !== undefined) {
      const currentSubGroup = [...currentQuestion.subGroup[parentIndex]];
      currentSubGroup[index] = {...question};
      currentQuestion.subGroup[parentIndex] = [...currentSubGroup];
    }
    questions[questionIndex] = Object.assign({}, {...currentQuestion});
    if (!editedQuestionsId.includes(currentQuestion.questionid)) {
      editedQuestionsId.push(currentQuestion.questionid);
    }
    return Object.assign({}, state, {
      questions,
      editedFlag: true,
      editedQuestionsId,
      lastChanged: new Date().getTime(),
    });
  }

  return state;
};

const deleteSubForm = (state, action) => {
  const {questionIndex, parentIndex} = action;
  const questions = [...state.questions];
  const {editedQuestionsId} = state;

  if (questions[questionIndex] !== undefined) {
    const currentQuestion = {...questions[questionIndex]};
    if (currentQuestion.subFormList[parentIndex] !== undefined) {
      let subFormList = currentQuestion.subFormList;
      subFormList.splice(action.parentIndex, 1);
      currentQuestion.subFormList = [...subFormList];
    }

    questions[questionIndex] = Object.assign({}, {...currentQuestion});
    if (!editedQuestionsId.includes(currentQuestion.questionid)) {
      editedQuestionsId.push(currentQuestion.questionid);
    }
    return Object.assign({}, state, {
      questions,
      editedFlag: true,
      editedQuestionsId,
      lastChanged: new Date().getTime(),
    });
  }
  return state;
};

const updateSubForm = (state, action) => {
  const {question, questionIndex, parentIndex} = action;
  const {editedQuestionsId} = state;
  const questions = [...state.questions];
  if (questions[questionIndex] !== undefined) {
    const currentQuestion = {...questions[questionIndex]};
    if (currentQuestion.subFormList[parentIndex] !== undefined) {
      const currentSubFormList = [...currentQuestion.subFormList];
      currentSubFormList[parentIndex] = {...question};
      currentQuestion.subFormList = [...currentSubFormList];
    }

    questions[questionIndex] = Object.assign({}, {...currentQuestion});

    if (!editedQuestionsId.includes(currentQuestion.questionid)) {
      editedQuestionsId.push(currentQuestion.questionid);
    }
    return Object.assign({}, state, {
      questions,
      editedFlag: true,
      editedQuestionsId,
      lastChanged: new Date().getTime(),
    });
  }

  return state;
};

const updateSubFormOption = (state, action) => {
  const questions = [...state.questions];
  const {editedQuestionsId} = state;
  const {index, questionIndex, subformIndex, value} = action;
  const tempQuestion = questions[subformIndex];
  const subQn = tempQuestion.subFormList[questionIndex];

  subQn.subgroupQuestions[index] = value;
  tempQuestion.subFormList[questionIndex] = subQn;
  questions[subformIndex] = Object.assign({}, {...tempQuestion});
  if (!editedQuestionsId.includes(tempQuestion.questionid)) {
    editedQuestionsId.push(tempQuestion.questionid);
  }
  return Object.assign({}, state, {
    questions,
    editedFlag: true,
    editedQuestionsId,
    lastChanged: new Date().getTime(),
  });
};

const deleteSubFormOption = (state, action) => {
  const questions = [...state.questions];
  const {editedQuestionsId} = state;
  const {index, questionIndex, subformIndex} = action;
  const tempQuestion = questions[subformIndex];
  let subFormList = [...tempQuestion.subFormList];
  const subQn = subFormList[questionIndex];

  subQn.subGroup.splice(index, 1);
  subQn.subgroupQuestions.splice(index, 1);
  subFormList[questionIndex] = {...subQn};
  tempQuestion.subFormList = [...subFormList];
  questions[subformIndex] = Object.assign({}, {...tempQuestion});
  if (!editedQuestionsId.includes(tempQuestion.questionid)) {
    editedQuestionsId.push(tempQuestion.questionid);
  }
  return Object.assign({}, state, {
    questions,
    editedFlag: true,
    editedQuestionsId,
    lastChanged: new Date().getTime(),
  });
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_TASK_SETTINGS:
      return Object.assign({}, state, {
        taskSettingsToggle: !state.taskSettingsToggle,
      });
    case UPDATE_QUESTIONS:
      return Object.assign({}, state, {
        questions: [...action.questions],
        currentIndex: state.currentIndex === -1 ? action.questions.length - 1 : state.currentIndex,
      });

    case UPDATE_QUESTION:
      return Object.assign({}, updateQuestion(state, action));
    case UPDATE_ADDON:
      return Object.assign({}, updateAddOn(state, action));
    case UPDATE_ADDONS:
      return Object.assign({}, state, {
        addOns: [...action.addOns],
        currentAddOnIndex: state.currentAddOnIndex === -1 ? action.addOns.length - 1 : state.currentAddOnIndex,
      });

    case DELETE_ADDON:
      state.addOns.splice(action.index, 1);
      if (action.index === state.currentAddOnIndex) {
        return Object.assign({}, state, {
          addOns: [...state.addOns],
          currentAddOnIndex: state.addOns.length - 1,
        });
      }
      return Object.assign({}, state, {
        addOns: [...state.addOns],
      });

    case TOGGLE_SETTINGS_VIEW:
      return Object.assign({}, state, {
        settingsView: action.value,
      });
    case DELETE_QUESTION:
      state.questions.splice(action.index, 1);
      if (action.index === state.currentIndex) {
        return Object.assign({}, state, {
          questions: [...state.questions],
          currentIndex: state.questions.length - 1,
        });
      }
      return Object.assign({}, state, {
        questions: [...state.questions],
      });
    case COPY_QUESTION:
      state.questions.push(action.copiedQuestion);
      return Object.assign({}, state, {
        questions: [...state.questions],
        currentIndex: state.questions.length - 1,
      });
    case UPDATE_CURRENT_INDEX:
      if (state.questions[action.index] === undefined) {
        return state;
      }
      return Object.assign({}, state, {
        currentIndex: action.index,
      });
    case UPDATE_CURRENT_ADDON_INDEX:
      if (state.addOns[action.index] === undefined) {
        return state;
      }
      return Object.assign({}, state, {
        currentAddOnIndex: action.index,
      });
    case UPDATE_TASK_DATA:
      return Object.assign({}, state, {...state, ...action.data});
    case UPDATE_TASK_QUESTIONS:
      return {...state, taskQuestions: action.taskQuestions};
    case UPDATE_TASK_NAME:
      return Object.assign({}, state, {taskName: action.name});
    case UPDATE_TASK_DESCRIPTION:
      return Object.assign({}, state, {
        taskDescription: action.descriptionObj.taskDescription,
        color: action.descriptionObj.color,
        icon: action.descriptionObj.icon,
        infoTask: action.descriptionObj.infoTask,
        label: action.descriptionObj.label,
      });
    case UPDATE_TASK_ID:
      return Object.assign({}, state, {
        taskId: action.id,
        taskName: state.taskName === '' ? 'Loading...' : state.taskName,
      });
    case UPDATE_TASK_SETTINGS:
      return Object.assign({}, state, {taskSettings: {...action.settings}});
    case SYNC_ALLOCATIONS:
      return Object.assign({}, state, {taskSettings: {...state.taskSettings, userGroupsSelected: action.userGroupsSelected}});
    case UPDATE_TASK_STATUS:
      return Object.assign({}, state, {status: action.status});
    case UPDATE_PERMISSION:
      return Object.assign({}, state, {permission: {...action.permission}});
    case UPDATE_LOADER:
      return Object.assign({}, updateLoader(state, action));
    case UPDATE_MANAGER_PERMISSION:
      return Object.assign({}, state, {
        managerPermissions: action.managerPermissions,
      });
    case UPDATE_QUESTIONS_DETAILS:
      return Object.assign({}, state, {questionsDetails: [...action.questionsDetails]});
    case UPDATE_MANAGER_PERMISSION_INDEX:
      const managerPermissions = {...state.managerPermissions}; // changed here
      managerPermissions[action.index] = action.managerDetails;
      return Object.assign({}, state, {managerPermissions});
    case UPDATE_META_DATA:
      return Object.assign({}, state, {metaData: {...action.metaData}});
    case DELETE_SUB_GROUP:
      return Object.assign({}, state, deleteSubGroup(state, action));
    case UPDATE_SUB_INDEX:
      return Object.assign({}, updateSubQuestion(state, action));
    case UPDATE_SUB_GROUP_QUESTION:
      return updateSubGroupOption(state, action);
    case DELETE_CURRENT_SUB_INDEX:
      return Object.assign({}, deleteSubQuestion(state, action));
    case UPDATE_SUBFORM_OPTION:
      return Object.assign({}, updateSubFormOption(state, action));
    case DELETE_SUBFORM_OPTION:
      return Object.assign({}, deleteSubFormOption(state, action));
    case DELETE_SUB_FORM:
      return deleteSubForm(state, action);
    case UPDATE_SUB_FORM:
      return updateSubForm(state, action);
    default:
      return state;
  }
}
