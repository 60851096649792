import {
  SET_TABS,
  SET_ACTIVE_TAB,
  SET_WORKFLOW_NAME,
  SET_TASK_NAME,
  SELECT_WORKFLOW,
  SET_PARENT_WORKFLOW,
  SET_PARENT_PRIMARY_RESPONSE,
} from './types';

export const setTabs = (tabs) => (dispatch) => {
  dispatch({
    type: SET_TABS,
    tabs,
  });
};

export const setActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_TAB,
    tab,
  });
};

export const setWorkflowName = (value) => (dispatch) => {
  dispatch({
    type: SET_WORKFLOW_NAME,
    value,
  });
};

export const setTaskName = (value) => (dispatch) => {
  dispatch({
    type: SET_TASK_NAME,
    value,
  });
};

export const selectWorkflow = (value) => (dispatch) => {
  dispatch({
    type: SELECT_WORKFLOW,
    value,
  });
};

export const setParentWorkflow = (value) => (dispatch) => {
  dispatch({
    type: SET_PARENT_WORKFLOW,
    value,
  });
};

export const setParentPrimaryResponse = (value) => (dispatch) => {
  dispatch({
    type: SET_PARENT_PRIMARY_RESPONSE,
    value,
  });
};
