import {
  SET_TABS,
  SET_ACTIVE_TAB,
  SET_WORKFLOW_NAME,
  SET_TASK_NAME,
  SELECT_WORKFLOW,
  SET_PARENT_WORKFLOW,
  SET_PARENT_PRIMARY_RESPONSE,
} from '../actions/types';

const initialState = {
  tabs: [],
  activeTab: 'main',
  workflowName: '',
  parentWorkflowName: '',
  taskName: '',
  workflowSelected: '',
  parentPrimaryResponse: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TABS:
      return {...state, tabs: action.tabs};
    case SET_ACTIVE_TAB:
      return {...state, activeTab: action.tab};
    case SET_WORKFLOW_NAME:
      return {...state, workflowName: action.value};
    case SET_TASK_NAME:
      return {...state, taskName: action.value};
    case SELECT_WORKFLOW:
      return {...state, workflowSelected: action.value};
    case SET_PARENT_WORKFLOW:
      return {...state, parentWorkflowName: action.value};
    case SET_PARENT_PRIMARY_RESPONSE:
      return {...state, parentPrimaryResponse: action.value};
    default:
      return state;
  }
}
