import {SET_CURRENT_LOCATIONS, SET_LAST_SYNCED, SET_USER_MODE, SET_USER_TIMELINE} from '../actions/types';

const initialState = {
  currentLocations: [],
  selectedUser: '',
  userMode: false,
  lastSynced: '',
  userTimleline: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_LOCATIONS:
      return {...state, currentLocations: action.data};
    case SET_USER_TIMELINE:
      return {...state, userTimleline: action.data};
    case SET_LAST_SYNCED:
      return {...state, lastSynced: action.data};
    case SET_USER_MODE:
      return {...state, userMode: action.data};
    default:
      return state;
  }
}
