import {
  OPEN_PDF_SETTINGS,
  SELECT_PDF_ELEMENT,
  SET_INVOICE_BODY_DATA,
  SET_INVOICE_FOOTER_DATA,
  SET_INVOICE_HEADER_DATA,
  UPDATE_PDF_NAME,
  UPDATE_PDF_SETTINGS,
} from '../actions/types';

const baseSetting = {
  value: '',
  type: 'static',
  settings: {
    workflowId: '',
    taskId: '',
    questionId: '',
    default: '',
  },
};

const initialState = {
  selectedElement: '',
  pdfSettingsToggle: false,
  pdfName: '',
  pdfSettings: {
    description: '',
    color: '',
    icon: '',
    template: 'Invoice classic',
  },
  invoiceHeaderData: {
    cmpName: baseSetting,
    cmpLogo: '',
    cmpAddress: baseSetting,
    invoiceId: baseSetting,
    gst: baseSetting,
    pan: baseSetting,
    invoiceDate: baseSetting,
  },
  invoiceBodyData: {
    billToAddress: baseSetting,
    billHolderName: baseSetting,
    clientName: baseSetting,
    clientGST: baseSetting,
    clientPAN: baseSetting,
    dueDate: baseSetting,
    shipToAddress: baseSetting,
    billTable: {
      settings: {
        workflowId: '',
        taskId: '',
        questionId: '',
      },
      columns: [
        {
          id: 'product',
          label: 'Product',
          questionId: '',
          type: 'text',
          default: '',
        },
        {
          id: 'quantity',
          label: 'Quantity',
          questionId: '',
          type: 'number',
          default: '',
        },
        {
          id: 'price',
          label: 'Price',
          questionId: '',
          type: 'number',
          default: '',
        },
        {
          id: 'discount',
          label: 'Discout %',
          questionId: '',
          type: 'percent',
          default: '',
        },
        {
          id: 'tax',
          label: 'Tax %',
          questionId: '',
          type: 'percent',
          default: '',
        },
        {
          id: 'amount',
          label: 'Amount',
          questionId: '',
          type: 'number',
          default: '',
        },
      ],
    },
    billTotal: baseSetting,
  },
  invoiceFooterData: {
    terms: baseSetting,
    signature: baseSetting,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_PDF_ELEMENT:
      return {
        ...state,
        selectedElement: action.data,
      };
    case SET_INVOICE_HEADER_DATA:
      return {
        ...state,
        invoiceHeaderData: action.data,
      };
    case SET_INVOICE_BODY_DATA: {
      return {
        ...state,
        invoiceBodyData: action.data,
      };
    }
    case SET_INVOICE_FOOTER_DATA: {
      return {
        ...state,
        invoiceFooterData: action.data,
      };
    }
    case UPDATE_PDF_NAME: {
      return {
        ...state,
        pdfName: action.data,
      };
    }
    case UPDATE_PDF_SETTINGS: {
      return {
        ...state,
        pdfSettings: action.data,
      };
    }
    case OPEN_PDF_SETTINGS: {
      return Object.assign({}, state, {
        pdfSettingsToggle: !state.pdfSettingsToggle,
      });
    }
    default:
      return state;
  }
}
