import TextField from '@material-ui/core/TextField';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {enqueueSnackbar} from '../../../redux/actions/notistackActions';
import {openPdfSettings, updatePdfName} from '../../../redux/actions/pdfActions';
import API from '../../../shared/api';
import APIUrl from '../../../shared/apiURL';
import {TaskToBackend} from '../../../shared/logic';
import SubmitButton from './TopbarProfile';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  state = {
    taskNameClick: false,
    newPdf: false,
    name: '',
  };

  componentDidMount = () => {
    let paths = window.location.pathname.split('/');
    if (paths[3] === 'workflow' && paths[4]) this.getWorkflowState(paths[4]);
    if (paths[5] === 'pdf' && paths[6] === 'new')
      this.setState({
        newPdf: true,
      });
  };

  componentDidUpdate = () => {
    let paths = window.location.pathname.split('/');
    if (this.state.newPdf && paths[6] !== 'new') {
      this.setState({
        newPdf: false,
      });
    }
  };

  getWorkflowState = (workflowId) => {
    if (workflowId) {
      API.doFetch('POST', {workflowId}, APIUrl.getWorkflow)
        .then((response) => {
          if (response.workflow && response.workflow.name) {
            this.setState({
              name: response.workflow.name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  savePdf = async (status) => {
    let paths = window.location.pathname.split('/');
    const {pdfName, pdfSettings, invoiceHeaderData, invoiceBodyData, invoiceFooterData} = this.props;
    const saveData = await API.doFetch(
      'POST',
      {
        pdfId: paths[6],
        name: pdfName,
        settings: {
          invoiceHeaderData,
          invoiceBodyData,
          invoiceFooterData,
        },
        pdfSettings,
      },
      APIUrl.addPDF,
    );
    if (saveData && saveData.pdfId) {
      this.props.enqueueSnackbar({
        message: 'Pdf Saved Successfully!',
        options: {
          key: 'pdfCreate',
          variant: 'success',
          preventDuplicate: true,
        },
      });
    }
  };

  render() {
    const {changeMobileSidebarVisibility, changeSidebarVisibility} = this.props;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <Link className="topbar__logo1" to="/home/workflows" />

            <h5
              style={{
                fontSize: '21px',
                fontWeight: '500',
                color: '#373737',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => window.history.back()}>
              {this.state.name || 'workflow'}
            </h5>
            {!this.state.newPdf && (
              <React.Fragment>
                <ArrowForwardIosIcon style={{width: '18px', marginTop: 'auto', marginBottom: 'auto'}} />
                <div style={{lineHeight: '20px', marginLeft: 10, marginTop: 'auto', marginBottom: 'auto'}} className="taskname-input-div">
                  {this.state.newPdf ? (
                    <span style={{fontSize: 15}}>New PDF</span>
                  ) : (
                    <TextField
                      id="taskname"
                      label="PDF Name"
                      variant="outlined"
                      className="taskname-input"
                      size="small"
                      value={this.props.pdfName}
                      onChange={(event) => this.props.updatePdfName(event.target.value)}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
          {!this.state.newPdf && (
            <div className="topbar__right" style={{height: '60px', display: this.state.newPdf ? 'none' : ''}}>
              <SettingsIcon onClick={this.props.openPdfSettings} style={{width: 80, cursor: 'pointer', fontSize: 30, marginTop: 15}} />
              <div style={{marginRight: 10, marginTop: 'auto', marginBottom: 'auto'}}>
                <SubmitButton TaskToBackend={this.savePdf} openTaskSettings={this.props.openPdfSettings} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.pdf,
  }),
  {
    updatePdfName,
    openPdfSettings,
    enqueueSnackbar,
  },
)(Topbar);
