import DownIcon from 'mdi-react/ChevronDownIcon';
import React, {PureComponent} from 'react';
import {Collapse} from 'reactstrap';

import TopbarMenuLink from './TopbarMenuLink';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopBarName extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({collapse: !prevState.collapse}));
  };

  changeTeam = (name) => {
    this.props.setTeamType(name);
  };

  render() {
    const {collapse} = this.state;
    if (this.props.parentWorkflowId) {
      return (
        <div className="topbar__profile" style={{margin: 0}}>
          <button type="button" className="topbar__avatar" style={{padding: 0}}>
            <p className="topbar__avatar-name left-name" style={{fontSize: 18, color: '#373737'}}>
              {this.props.name}
            </p>
          </button>
        </div>
      );
    }

    return (
      <div className="topbar__profile" style={{margin: 0}}>
        <button type="button" className="topbar__avatar" onClick={this.toggle} style={{padding: 0}}>
          <h5
            className={'fp-text-sub-heading-1'}
            style={{
              color: '#333333',
              marginTop: 'auto',
              marginBottom: 'auto',
              cursor: this.props.teamType ? 'pointer' : 'auto',
            }}>
            {this.props.teamType === 'Main User Group' ? 'App Users List' : this.props.teamType}
          </h5>
          <DownIcon className="topbar__icon" style={{fill: '#373737'}} />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {this.props.teams.map((item) => (
              <TopbarMenuLink title={item.name} icon="list" companyId={this.props.companyId} changeTeam={this.changeTeam} />
            ))}
          </div>
        </Collapse>
      </div>
    );
  }
}
