// const appUrl = 'https://dev-app-fieldproxy-backend-dot-fieldproxyproduction.et.r.appspot.com/v2/';
const appUrl = 'https://port3003.fieldproxy.com/v2/';

let host = 'http://attendance.ssn.edu.in:3002/v2/';
let base = 'http://attendance.ssn.edu.in:3002';
// let host = 'https://app-backend-dev-dot-fieldproxyproduction.et.r.appspot.com/v2/';
// let base = 'https://app-backend-dev-dot-fieldproxyproduction.et.r.appspot.com';
let v3 = 'https://app-backend-dev-dot-fieldproxyproduction.et.r.appspot.com/v3/';
if (process.env.REACT_APP_LOCAL === 'true') {
  host = 'http://localhost:3002/v2/';

  base = 'http://localhost:3002';
}

// eslint-disable-next-line import/no-default-export
export default {
  API_URL: base,
  // API_URL: 'http://localhost:3002',
  createTabInit: `${base}/V1/task/createTabInit`,
  getAllocationUsers: `${host}workflows/getAllocationUsers`,
  getManagersList: `${host}company/getManagersList`,
  deleteTask: `${host}/task/deleteTask`,
  signin: `${host}company/signin`,
  signup: `${host}company/signup`,
  updateProfile: `${host}company/updateProfile`,
  getProfile: `${host}company/getProfile`,
  getRegistrationUrl: `${host}adminpanel/getRegistrationUrl`,
  checkEmail: `${host}company/checkEmailID`,
  resetPassword: `${host}company/resetPassword`,
  checkCompany: `${host}company/checkCompanyID`,
  upload: `${host}file/upload`,
  getTask: `${host}task/taskList`,
  addTask: `${host}task/addTask`,
  addPDF: `${host}pdf/addPDF`,
  addTask1: `${host}task/addTaskDataset`,
  taskUpdate: `${host}task/updateTask`,
  dataSet: `${host}data/excelList`,
  dataSetDetail: `${host}data/getExcelDetails`,
  dataSetData: `${host}data/getExcelData`,
  dataSetUpdate: `${host}data/updateOne`,
  dataSetUpload: `${host}data/upload`,
  dataSetAddRow: `${host}data/addOne`,
  dataSetDeleteRow: `${host}data/deleteOne`,
  dataSetDeleteTable: `${host}data/deleteTable`,
  dataSetUpdateHeaders: `${host}data/updateHeaders`,
  dataSetLimited: `${host}data/getColumnValues`,
  downloadDataset: `${host}data/getExcelDataLtd`,

  dashboardBasicResponse: `${host}dashboard/basicMeasure/responses`,
  dashboardBasicCheckIn: `${host}dashboard/basicMeasure/checkin`,
  dashboardBasicKilometer: `${host}dashboard/basicMeasure/kilometerTravelled`,
  dashboardBasicWorkFlows: `${host}dashboard/basicMeasure/workflows`,

  workFlowList: `${host}workflows/getWorkflows`,
  addNewWorkflow: `${host}workflows/addWorkflow`,
  getWorkflow: `${host}workflows/getWorkflow`,
  workflowStatus: `${host}workflows/workflowStatus`,
  ArchiveWorkflow: `${host}workflows/archiveWorkflow`,
  recordWorkflow: `${host}workflows/recordWorkflow`,
  getSubWorkflows: `${host}workflows/getSubWorkflows`,
  // apiURL : 'http://localhost:4000/api/',
  getRespondedUsers: `${host}workflows/getUserOptions`,

  forum: `${host}forum`,
  forumComment: `${host}forum/comment`,
  forumClose: `${host}forum/close`,

  taskDetails: `${host}task/getTaskDetails`,
  QuestionDetails: `${host}task/getQuestionDetails`,
  TaskResponses: `${host}task/getTaskResponses`,
  EditTaskResponse: `${host}task/editTaskResponse`,
  DownloadResponses: `${host}task/downloadResponses`,
  responsesForFilters: `${host}task/responsesForFilters`,
  WorkFlowResponses: `${host}workflows/getWorkflowResponses`,
  WorkflowIdentifier: `${appUrl}workflow/getworkflowIdentifier`,
  WorkflowIdentifiers: `${appUrl}workflow/getworkflowIdentifiers`,
  AppSync: `${appUrl}workflow/sync`,
  TokenForUser: `${host}agent/getTokenforUser`,
  WorkflowOverview: `${host}workflows/getWorkflowOverview`,
  OnGoingFlow: `${v3}workflows/getOngoingFlow`,
  AddAgent: `${host}agent/mainGroup/individual`,
  MainUserGroup: `${host}agent/mainGroup`,
  Team: `${host}agent/team`,
  TeamMaingroup: `${host}agent/team/mainGroup`,
  AddTeam: `${host}agent/team/individual`,
  IsUniqueName: `${host}agent/team/isUniqueName`,
  UserList: `${host}task/getUserList`,
  Manager: `${host}manager`,
  ManagerAdd: `${host}manager/add`,
  ManagerDecode: `${host}manager/decode`,

  createdDetails_permission: `${host}permissions/createdDetails/`,
  permission: `${host}permissions`,

  Identifier: `${host}workflows/identifierDetails`,
  sync: `${host}workflows/sync`,
  formula: `${appUrl}workflow/sync`,

  sendSms: `${appUrl}sms/trigger/question/`,
  triggerOtp: `${appUrl}sms/triggerOtp/question/`,
  resendOtp: `${appUrl}sms/triggerOtp/question/`,
  verifyOtp: `${appUrl}sms/verifyotp/question/`,

  // dashboard
  DashboardResponses: `${host}dashboard/basicMeasure/workflow-responses`,
  DashboardCheckIn: `${host}dashboard/basicMeasure/checkin`,
  DashboardReview: `${host}dashboard/basicMeasure/pending-approval`,

  DashboardFilter: `${host}dashboard/basicMeasure/filter`,

  DashboardMap: `${host}dashboard/mapview`,
  DashboardAgentMetric: `${host}dashboard/agent-metric`,
  DashboardTaskMetric: `${host}dashboard/task-metric`,
  DashboardWorkflowMetric: `${host}dashboard/workflow-metric`,
  DashboardReminders: `${host}reminders/getReminder`,

  TokenValidity: `${host}company/tokenValidity`,
  Approval: `${host}task/approval`,

  liveLocation: `${host}agent/live`,
  liveTimeline: `${host}agent/getpath`,
  validatePublicLink: `${host}agent/validatePublicLink`,

  addPDF: `${host}pdf/addPDF`,
  getPDF: `${host}pdf`,
  getPdfResponse: `${host}pdf/viewResponses`,
  downloadPdf: `${host}pdf/downloadPdf`,
  getPublicLink: `${host}agent/getPublicLink`,

  getSubscriptions: `${host}adminPanel/getAllSubscription`,
  getAllInvoice: `${host}adminPanel/getAllInvoice`,
  getNewOrder: `${host}adminPanel/getNewOrder`,
  updatePayment: `${host}adminpanel/updatePayment`,

  reportList: `${host}reports/reportList`,
  createReport: `${host}reports/createReport`,
  customReport: `${host}reports/customReport`,

  cloneTemplate: `${host}template/clonetemplate`,
  // customReportdev: `${appUrl}reports/customReport`

  updateOnboardStatus: `${host}task/updateOnboard`,

  // addOns
  getAddOnDetails: `${host}task/getAddOnDetails`,

  // notifications
  getNotifications: `${host}adminpanel/getClientNofitication`,
  readReceipts: `${host}adminpanel/notifications/readReceipts`,
  pingtoSlack: `${host}pingtoSlack`,

  //sharelink
  addLink: `${host}publicform/addPublicForm`,
  getShareLinkInfo: `${host}publicform/getFormDetails`,
};
